import { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";

import { NotificationContext } from "context/notification";

import { GET_REVIEW_BY_ID } from "api/query/get-review-by-id";
import { UPDATE_REVIEW } from "api/mutations/review";

import Drawer from "common/components/drawer";
import Input from "common/components/input";
import Textarea from "common/components/textarea";
import Button from "common/components/button";

const inputs = [
  {
    label: "Name",
    key: "name",
  },
  {
    label: "Avatar",
    key: "avatar",
  },
  {
    label: "Rate",
    key: "rate",
  },
];

const textareas = [
  {
    label: "Description",
    key: "description",
  },
];

const ReviewDrawer = ({ reviewId, refetchReviews, forceCloseDrawer }) => {
  const [payload, setPayload] = useState({});

  const { setNotification } = useContext(NotificationContext);

  const { data: { getReviewById: review } = {} } = useQuery(GET_REVIEW_BY_ID, {
    variables: {
      id: reviewId,
    },
  });

  const [updateReview, { loading }] = useMutation(UPDATE_REVIEW, {
    onCompleted: (d) => {
      setNotification({
        type: "success",
        message: "Successfully updated",
      });
      forceCloseDrawer(true);
      refetchReviews();
    },
    onError: (d) => {
      setNotification({
        type: "error",
        message: "Something went wrong",
      });
    },
  });

  useEffect(() => {
    if (review) {
      setPayload(review);
    }
  }, [review]);

  const handleUpdate = () => {
    updateReview({
      variables: {
        reviewID: reviewId,
        payload: {
          name: payload.name,
          description: payload.description,
          rate: parseInt(payload.rate),
          avatar: payload.avatar,
        },
      },
    });
  };

  return (
    <div className="flex flex-col gap-6">
      {inputs.map((input, idx) => (
        <Input
          key={idx}
          label={input.label}
          placeholder={input.label}
          defaultValue={payload[input.key]}
          onChange={(text) =>
            setPayload((prevState) => ({ ...prevState, [input.key]: text }))
          }
        />
      ))}

      {textareas.map((input, idx) => (
        <Textarea
          key={idx}
          label={input.label}
          placeholder={input.label}
          defaultValue={payload[input.key]}
          onChange={(text) =>
            setPayload((prevState) => ({ ...prevState, [input.key]: text }))
          }
        />
      ))}

      <Button
        title="Save changes"
        className="text-indigo-600 border-indigo-600 hover:text-indigo-500 hover:border-indigo-500"
        onClick={handleUpdate}
      />
    </div>
  );
};

const Edit = ({ refetchReviews }) => {
  const [forceClose, setForceClose] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const closeDrawer = () => {
    navigate("/reviews");
  };

  return (
    <Drawer
      active={true}
      onClose={closeDrawer}
      title="Edit Review"
      forceClose={forceClose}
      setForceClose={setForceClose}
      component={
        <ReviewDrawer
          refetchReviews={refetchReviews}
          forceCloseDrawer={setForceClose}
          reviewId={params.id}
        />
      }
    />
  );
};

export default Edit;
