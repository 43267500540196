import { gql } from "@apollo/client";

export const OVERVIEW_STATISTICS = gql`
	query GetOverviewStatistics($startDate: String, $endDate: String) {
		overviewStatistics(payload: { startDate: $startDate, endDate: $endDate }) {
			totalUsers
			paid
			toPay
			totalEarnings
			profit
			activeUsers
			newUsers
			leaderboardBonuses
			otherBonuses
			labels
			earningsByCountry {
				name
				value
			}
			earningsByOfferwall {
				name
				value
			}
			earningsByReferral {
				name
				value
			}
			earningsByDate {
				name
				value
			}
		}
	}
`;
