import { gql } from "@apollo/client";

export const GET_ALL_USERS = gql`
	query GetAllUsers(
		$filter: UserWhereInput
		$page: Int
		$sortBy: SortByInput
		$referralUserId: ID
	) {
		getAllUsers(
			filter: $filter
			page: $page
			sortBy: $sortBy
			referralUserId: $referralUserId
		) {
			total
			users {
				id
				name
				visitorId
				email
				phone
				username
				lastIp
				registrationIp
				promoter
				banned
				gid
				ofid
				conversionFired
				verified
				emailVerified
				duplicate
				invalidIp
				upline
				source
				type
				isEditor
				isAdmin
				country {
					name
					code
				}
				referralUser {
					id
					username
				}
			}
		}
	}
`;
