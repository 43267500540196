import { gql } from "@apollo/client";

export const GET_REVIEW_BY_ID = gql`
  query GetReviewById($id: ID!) {
    getReviewById(id: $id) {
      id
      name
      description
      rate
      avatar
    }
  }
`;
