import { gql } from "@apollo/client";

export const GET_ANNOUNCEMENT_BY_ID = gql`
  query GetAnnouncementById($id: ID!) {
    getAnnouncementById(id: $id) {
      id
      title
      description
      createdAt
    }
  }
`;
