import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { Transition } from "@headlessui/react";
import { UsersIcon, CurrencyDollarIcon } from "@heroicons/react/outline";
import { DateRangePicker } from "react-date-range";
import OutsideClickHandler from "react-outside-click-handler";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { OVERVIEW_STATISTICS } from "api/query/overview-statistics";

import { getChartDataset, getChartOptions } from "utilities";

import Stats from "common/components/stats";
import Button from "common/components/button";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const labels = ["January", "February", "March", "April", "May", "June", "July"];

const generateFakeValues = () => {
	const array = [];
	for (let i = 0; i < 12; i++) {
		array.push(Math.floor(Math.random() * Math.floor(1000)));
	}
	return array;
};

const colors = ["#52A2F9", "#5FE1E0", "#F95263", "#F9A352", "#D852F9"];

const data = (data = [], labels = []) => ({
	labels,
	datasets: data.map((row, idx) => getChartDataset(row.name, row.value, colors[idx])),
});

export default function OverviewStatistics({ setSidebarOpen }) {
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [date, setDate] = useState({
		startDate: dayjs().subtract(6, "d").toDate(),
		endDate: dayjs().toDate(),
		key: "selection",
	});

	const { data: { overviewStatistics } = {}, refetch: refetchStatistics } = useQuery(
		OVERVIEW_STATISTICS,
		{
			variables: {
				startDate: date.startDate,
				endDate: date.endDate,
			},
		}
	);

	return (
		<>
			<div className="flex justify-end w-full relative z-10">
				<OutsideClickHandler
					onOutsideClick={() => {
						setShowDatePicker(false);
					}}
				>
					<Button
						title={`${dayjs(date.startDate).format("DD/MM/YYYY")} - ${dayjs(
							date.endDate
						).format("DD/MM/YYYY")}`}
						onClick={() => setShowDatePicker((prevState) => !prevState)}
					/>
					<Transition
						show={showDatePicker}
						enter="transition-all duration-150"
						enterFrom="-translate-y-[30px] opacity-0"
						enterTo="translate-y-0 opacity-1"
						leave="transition-all duration-150"
						leaveFrom="translate-y-0 opacity-1"
						leaveTo="-translate-y-[30px] opacity-0"
						className="z-10"
					>
						<DateRangePicker
							onChange={(item) =>
								setDate((prevState) => ({
									...prevState,
									startDate: item?.selection?.startDate || item?.range1?.startDate,
									endDate: dayjs(item?.selection?.endDate || item?.range1?.endDate)
										.endOf("d")
										.toDate(),
								}))
							}
							showSelectionPreview={true}
							moveRangeOnFirstSelection={false}
							className="rounded-md shadow absolute top-[110%] right-0"
							months={1}
							direction="horizontal"
							ranges={[date]}
						/>
					</Transition>
				</OutsideClickHandler>
			</div>
			<div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-5 3xl:grid-cols-6 gap-5">
				<Stats
					name="Total users"
					total={overviewStatistics?.totalUsers}
					absolute="All time"
					StatIcon={UsersIcon}
				/>
				<Stats
					name="Paid"
					total={overviewStatistics?.paid}
					StatIcon={CurrencyDollarIcon}
					showChange={false}
				/>
				<Stats
					name="To pay"
					total={overviewStatistics?.toPay}
					StatIcon={CurrencyDollarIcon}
					showChange={false}
				/>
				<Stats
					name="Total earnings"
					total={overviewStatistics?.totalEarnings}
					StatIcon={CurrencyDollarIcon}
					showChange={false}
				/>
				<Stats
					name="Profit"
					total={overviewStatistics?.profit}
					StatIcon={CurrencyDollarIcon}
					showChange={false}
				/>
				<Stats
					name="Active users"
					total={overviewStatistics?.activeUsers}
					StatIcon={UsersIcon}
					showChange={false}
				/>
				<Stats
					name="New Users"
					total={overviewStatistics?.newUsers}
					StatIcon={UsersIcon}
					showChange={false}
				/>
				<Stats
					name="Leaderboard Bonuses"
					total={overviewStatistics?.leaderboardBonuses}
					StatIcon={CurrencyDollarIcon}
					showChange={false}
				/>
				<Stats
					name="Other Bonuses"
					total={overviewStatistics?.otherBonuses}
					StatIcon={CurrencyDollarIcon}
					showChange={false}
				/>
			</div>
			<div className="grid grid-cols-1 2xl:grid-cols-2 gap-5 my-5">
				{[
					{ title: "Earnings", key: "earningsByDate" },
					{ title: "Earnings by Offerwall", key: "earningsByOfferwall" },
					{ title: "Earnings by Country", key: "earningsByCountry" },
					{ title: "Referral Earnings", key: "earningsByReferral" },
				].map((chart, idx) => (
					<div key={idx} className="px-10 py-3 shadow inline-block rounded-lg bg-white">
						<Line
							type="line"
							data={data(overviewStatistics?.[chart.key], overviewStatistics?.labels)}
							options={getChartOptions(chart.title)}
							height={300}
						/>
					</div>
				))}
			</div>
		</>
	);
}
