import { gql } from "@apollo/client";

export const UPDATE_TASK = gql`
	mutation UpdateTask($payload: PayloadTaskUpdate!, $taskId: ID!) {
		updateTask(payload: $payload, taskId: $taskId)
	}
`;

export const CREATE_TASK = gql`
	mutation CreateTask($payload: PayloadTaskCreate!) {
		createTask(payload: $payload) {
			id
		}
	}
`;

export const DELETE_TASK = gql`
	mutation DestroyTask($id: ID!) {
		destroyTask(id: $id)
	}
`;
