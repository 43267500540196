import { gql } from "@apollo/client";

export const GET_ALL_UPLOADS = gql`
  query GetAllUploads($filter: FilterUpload) {
    getAllUploads(filter: $filter) {
      uploads {
        id
        name
        link
      }
      total
    }
  }
`;
