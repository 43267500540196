import { gql } from "@apollo/client";

export const UPDATE_WITHDRAWAL_METHOD = gql`
  mutation UpdateWithdrawalMethod(
    $payload: UpdateWithdrawalMethodInput
    $withdrawalMethodId: ID!
  ) {
    updateWithdrawalMethod(payload: $payload, withdrawalMethodId: $withdrawalMethodId)
  }
`;

export const ADD_WITHDRAWAL_METHOD = gql`
  mutation AddWithdrawalMethod($payload: AddWithdrawalMethodInput) {
    addWithdrawalMethod(payload: $payload) {
      id
    }
  }
`;

export const DELETE_WITHDRAWAL_METHOD = gql`
  mutation DeleteWithdrawalMethod($withdrawalMethodId: ID!) {
    deleteWithdrawalMethod(withdrawalMethodId: $withdrawalMethodId)
  }
`;
