import { useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate, Routes, Route, Link } from "react-router-dom";
import { CheckIcon, XIcon, PlusCircleIcon } from "@heroicons/react/outline";

import { PencilIcon } from "@heroicons/react/outline";

import { NotificationContext } from "context/notification";

import EditWithdrawalMethod from "./components/edit";
import AddWithdrawalMethod from "./components/add";

import Drawer from "common/components/drawer";
import Card from "common/components/card";
import Modal from "common/components/modal";
import Button from "common/components/button";

import { GET_ALL_WITHDRAWAL_METHODS } from "api/query/get-all-withdrawal-methods";
import { DELETE_WITHDRAWAL_METHOD } from "api/mutations/withdrawal-method";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const getIcon = (state) => {
  return state ? (
    <CheckIcon className="w-5 h-5 ml-[50%] -translate-x-1/2" color="#22c55e" />
  ) : (
    <XIcon className="w-5 h-5 ml-[50%] -translate-x-1/2" color="#ef4444" />
  );
};

export default function WithdrawalMethods({ setSidebarOpen }) {
  const [modalActive, setModalActive] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { setNotification } = useContext(NotificationContext);
  const navigate = useNavigate();

  const {
    data: { getAllWithdrawalMethods: withdrawalMethods } = {},
    refetch: refetchWithdrawalMethods,
  } = useQuery(GET_ALL_WITHDRAWAL_METHODS, {});

  const [deleteWithdrawalMethodMutation, { loading }] = useMutation(
    DELETE_WITHDRAWAL_METHOD,
    {
      onCompleted: (d) => {
        if (d?.deleteWithdrawalMethod) {
          setNotification({
            type: "success",
            message: "Successfully deleted",
          });
          refetchWithdrawalMethods();
        } else {
          setNotification({
            type: "error",
            message: "Something went wrong",
          });
        }
      },
      onError: (d) => {
        setNotification({
          type: "error",
          message: "Something went wrong",
        });
      },
    }
  );

  const onEdit = (withdrawalMethod) => {
    navigate(`edit/${withdrawalMethod.id}`);
  };

  const deleteWithdrawalMethod = (id) => {
    if (id !== null) {
      deleteWithdrawalMethodMutation({
        variables: {
          withdrawalMethodId: id,
        },
      });
    }
  };

  const onDelete = (withdrawalMethod) => {
    setDeleteId(withdrawalMethod.id);
    setModalActive(true);
  };

  const onModalClose = () => {
    setDeleteId(null);
  };

  const addWithdrawalMethodDrawer = () => {
    navigate("add");
  };

  const generateDescriptionForMethod = (method = {}) => {
    return (
      <div className="text-xs text-gray-500">
        <p>
          Provider: <span className="text-gray-400">{method.provider}</span>
        </p>
        <p>
          Payout: <span className="text-gray-400">{method.payout}</span>
        </p>
      </div>
    );
  };

  return (
    <div className="mx-4">
      <Routes>
        <Route
          path="/edit/:id"
          element={
            <EditWithdrawalMethod refetchWithdrawalMethods={refetchWithdrawalMethods} />
          }
        />
        <Route
          path="/add"
          element={
            <AddWithdrawalMethod refetchWithdrawalMethods={refetchWithdrawalMethods} />
          }
        />
      </Routes>
      <Modal
        active={modalActive}
        setActive={setModalActive}
        title="Are you sure?"
        message="You are about to delete an withdrawal method"
        onSubmit={() => deleteWithdrawalMethod(deleteId)}
      />
      <div className="px-4 py-4 sm:px-6 md:px-0">
        <h1 className="text-3xl font-bold text-gray-700">Withdrawal Methods</h1>
      </div>
      <div className="my-4 flex flex-wrap gap-4">
        {withdrawalMethods?.map((withdrawalMethod, idx) => (
          <Card
            key={idx}
            image={withdrawalMethod.image}
            title={withdrawalMethod.name}
            description={generateDescriptionForMethod(withdrawalMethod)}
            onEdit={() => onEdit(withdrawalMethod)}
            onDelete={() => onDelete(withdrawalMethod)}
          />
        ))}
        <div
          className="shadow bg-white min-h-[200px] w-[210px] max-h-[300px] rounded-md overflow-hidden relative mx-auto xs:mx-0 cursor-pointer flex justify-center items-center flex-col gap-1"
          onClick={addWithdrawalMethodDrawer}
        >
          <PlusCircleIcon className="w-8 h-8 text-gray-400" />
          <span className="text-xs text-gray-400">Add new</span>
        </div>
      </div>
    </div>
  );
}
