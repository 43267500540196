import { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";

import { NotificationContext } from "context/notification";

import { GET_QUESTION_BY_ID } from "api/query/get-question-by-id";
import { UPDATE_QUESTION } from "api/mutations/question";

import Drawer from "common/components/drawer";
import Input from "common/components/input";
import Textarea from "common/components/textarea";
import Button from "common/components/button";

const inputs = [
  {
    label: "Question *",
    key: "question",
  },
];

const textareas = [
  {
    label: "Answer *",
    key: "answer",
  },
];

const QuestionDrawer = ({ questionId, refetchQuestions, forceCloseDrawer }) => {
  const [payload, setPayload] = useState({});

  const { setNotification } = useContext(NotificationContext);

  const { data: { getQuestionById: question } = {} } = useQuery(GET_QUESTION_BY_ID, {
    variables: {
      id: questionId,
    },
  });

  const [updateQuestion, { loading }] = useMutation(UPDATE_QUESTION, {
    onCompleted: (d) => {
      setNotification({
        type: "success",
        message: "Successfully updated",
      });
      forceCloseDrawer(true);
      refetchQuestions();
    },
    onError: (d) => {
      setNotification({
        type: "error",
        message: "Something went wrong",
      });
    },
  });

  useEffect(() => {
    if (question) {
      setPayload(question);
    }
  }, [question]);

  const handleUpdate = () => {
    updateQuestion({
      variables: {
        id: questionId,
        payload: {
          question: payload.question,
          answer: payload.answer,
        },
      },
    });
  };

  return (
    <div className="flex flex-col gap-6">
      {inputs.map((input, idx) => (
        <Input
          key={idx}
          label={input.label}
          placeholder={input.label}
          defaultValue={payload[input.key]}
          onChange={(text) =>
            setPayload((prevState) => ({ ...prevState, [input.key]: text }))
          }
        />
      ))}

      {textareas.map((input, idx) => (
        <Textarea
          key={idx}
          label={input.label}
          placeholder={input.label}
          defaultValue={payload[input.key]}
          onChange={(text) =>
            setPayload((prevState) => ({ ...prevState, [input.key]: text }))
          }
        />
      ))}

      <Button
        title="Save changes"
        className="text-indigo-600 border-indigo-600 hover:text-indigo-500 hover:border-indigo-500"
        onClick={handleUpdate}
      />
    </div>
  );
};

const Edit = ({ refetchQuestions }) => {
  const [forceClose, setForceClose] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const closeDrawer = () => {
    navigate("/faq");
  };

  return (
    <Drawer
      active={true}
      onClose={closeDrawer}
      title="Edit Question"
      forceClose={forceClose}
      setForceClose={setForceClose}
      component={
        <QuestionDrawer
          refetchQuestions={refetchQuestions}
          forceCloseDrawer={setForceClose}
          questionId={params.id}
        />
      }
    />
  );
};

export default Edit;
