import { gql } from "@apollo/client";

export const GET_CONVERSION_POINTS_BY_ID = gql`
	query GetConversionPointsById($id: ID!) {
		getConversionPointsById(id: $id) {
			id
			name
			value
			link
			active
			pricePerConversion
			hash
		}
	}
`;
