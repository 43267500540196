import { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";

import { NotificationContext } from "context/notification";

import { GET_OFFERWALL_BY_ID } from "api/query/get-offerwall-by-id";
import { UPDATE_OFFERWALL } from "api/mutations/offerwall";

import Drawer from "common/components/drawer";
import Input from "common/components/input";
import Button from "common/components/button";
import Switch from "common/components/switch";
import Select from "common/components/select";

const chargebackType = {
  negativeAmount: 1,
  statusCode: 0,
};

const chargebackSelect = [
  {
    id: 1,
    value: "Status Code",
    key: "statusCode",
  },
  {
    id: 2,
    value: "Negative Amount",
    key: "negativeAmount",
  },
];

const inputs = [
  {
    label: "Name",
    key: "name",
  },
  {
    label: "Priority",
    key: "priority",
  },
  {
    label: "Image",
    key: "image",
  },
  {
    label: "Iframe",
    key: "iframe",
  },
  {
    label: "Postback whitelist",
    key: "whitelist",
  },
  {
    label: "Chargeback completed code",
    key: "completedStatusCode",
  },
  {
    label: "Chargeback rejected code",
    key: "rejectedStatusCode",
  },
  {
    label: "Sticker",
    key: "sticker",
  },
  {
    label: "Countries (separated by comma)",
    key: "countries",
  },
  {
    label: "Level Restriction",
    key: "levelRestriction",
  },
];

const OfferwallDrawer = ({ offerwallId, refetchOfferwalls, forceCloseDrawer }) => {
  const [payload, setPayload] = useState({});
  const [popular, setPopular] = useState();
  const [hasSurveys, setHasSurveys] = useState();
  const [active, setActive] = useState();
  const [highEarning, setHighEarning] = useState();
  const [hideFromProxy, setHideFromProxy] = useState();

  const { setNotification } = useContext(NotificationContext);

  const { data: { getOfferwallById: offerwall } = {} } = useQuery(GET_OFFERWALL_BY_ID, {
    variables: {
      offerwallId,
    },
  });

  const [updateOfferwall, { loading }] = useMutation(UPDATE_OFFERWALL, {
    onCompleted: (d) => {
      setNotification({
        type: "success",
        message: "Successfully updated",
      });
      forceCloseDrawer(true);
      refetchOfferwalls();
    },
    onError: (d) => {
      setNotification({
        type: "error",
        message: "Something went wrong",
      });
    },
  });

  useEffect(() => {
    if (offerwall) {
      setPayload(offerwall);
      setPopular(offerwall.isPopular);
      setHighEarning(offerwall.highEarning);
      setHasSurveys(offerwall.hasSurveys);
      setActive(offerwall.active);
      setHideFromProxy(offerwall.hideFromProxy);
    }
  }, [offerwall]);

  const handleUpdate = () => {
    updateOfferwall({
      variables: {
        offerwallId,
        payload: {
          name: payload.name,
          priority: parseInt(payload.priority, 10),
          levelRestriction: parseInt(payload.levelRestriction, 10),
          image: payload.image,
          sticker: payload.sticker,
          iframe: payload.iframe,
          chargebackType: payload.chargebackType,
          completedStatusCode: payload.completedStatusCode,
          rejectedStatusCode: payload.rejectedStatusCode,
          whitelist: payload.whitelist,
          countries: payload.countries,
          isPopular: popular,
          highEarning,
          hasSurveys,
          active,
          hideFromProxy,
        },
      },
    });
  };

  return (
    <div className="flex flex-col gap-6">
      {inputs.map((input, idx) => (
        <Input
          key={idx}
          label={input.label}
          placeholder={input.label}
          defaultValue={payload[input.key]}
          onChange={(text) =>
            setPayload((prevState) => ({ ...prevState, [input.key]: text }))
          }
        />
      ))}

      <Select
        defaultValue={chargebackType[payload.chargebackType]}
        label="Chargeback Type"
        data={chargebackSelect}
        onChange={(item) => {
          setPayload((prevState) => ({
            ...prevState,
            chargebackType: item.key,
          }));
        }}
      />

      <Switch enabled={popular} setEnabled={setPopular} label="Popular" />
      <Switch enabled={hasSurveys} setEnabled={setHasSurveys} label="Has surveys" />
      <Switch enabled={highEarning} setEnabled={setHighEarning} label="High earning" />
      <Switch enabled={active} setEnabled={setActive} label="Active" />
      <Switch
        enabled={hideFromProxy}
        setEnabled={setHideFromProxy}
        label="Hide from Proxy"
      />

      <Button
        title="Save changes"
        className="text-indigo-600 border-indigo-600 hover:text-indigo-500 hover:border-indigo-500"
        onClick={handleUpdate}
      />
    </div>
  );
};

const Edit = ({ refetchOfferwalls }) => {
  const [forceClose, setForceClose] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const closeDrawer = () => {
    navigate("/offerwalls");
  };

  return (
    <Drawer
      active={true}
      onClose={closeDrawer}
      title="Edit Offerwall"
      forceClose={forceClose}
      setForceClose={setForceClose}
      component={
        <OfferwallDrawer
          refetchOfferwalls={refetchOfferwalls}
          forceCloseDrawer={setForceClose}
          offerwallId={params.id}
        />
      }
    />
  );
};

export default Edit;
