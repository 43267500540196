import { gql } from "@apollo/client";

export const UPDATE_FEATURED_OFFER = gql`
  mutation UpdateFeaturedOffer($payload: FeaturedOfferUpdate!, $featuredOfferId: ID!) {
    updateFeaturedOffer(payload: $payload, featuredOfferId: $featuredOfferId)
  }
`;

export const ADD_FEATURED_OFFER = gql`
  mutation AddFeaturedOffer($payload: AddFeaturedOffer!) {
    addFeaturedOffer(payload: $payload) {
      id
    }
  }
`;

export const DELETE_FEATURED_OFFER = gql`
  mutation DeleteFeaturedOffer($featuredOfferId: ID!) {
    deleteFeaturedOffer(id: $featuredOfferId)
  }
`;
