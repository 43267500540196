import { gql } from "@apollo/client";

export const GET_ALL_WITHDRAWALS = gql`
	query GetAllWithdrawals(
		$filter: WhereWithdrawalFilter
		$userId: ID
		$page: Int
		$sortBy: SortByInput
	) {
		getAllWithdrawals(filter: $filter, userId: $userId, page: $page, sortBy: $sortBy) {
			total
			withdrawals {
				id
				createdAt
				user {
					id
					username
				}
				amount
				payout
				status
				transactionHex
				withdrawalMethod
				paymentInfo
			}
		}
	}
`;
