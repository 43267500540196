import { gql } from "@apollo/client";

export const GET_ALL_BACKUPS = gql`
  query GetAllBackups {
    getAllBackups {
      name
      link
    }
  }
`;
