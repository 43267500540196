import { gql } from "@apollo/client";

export const GET_ALL_FEATURED_OFFERS = gql`
  query GetFeaturedOffers {
    getFeaturedOffers {
      id
      name
      image
      countries
      link
      payout
      provider
      description
    }
  }
`;
