import { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Cookie from "nookies";

import { MATIX_TOKEN } from "common/constants";

import { NotificationContext } from "context/notification";

import Drawer from "common/components/drawer";
import Input from "common/components/input";
import Dropzone from "common/components/dropzone";
import Button from "common/components/button";

const inputs = [
  {
    label: "Name",
    key: "name",
  },
];

const UploadDrawer = ({ forceCloseDrawer, refetchUploads }) => {
  const [payload, setPayload] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [token, setToken] = useState(Cookie.get(MATIX_TOKEN)[MATIX_TOKEN]);

  const { setNotification } = useContext(NotificationContext);

  const handleAdd = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      setNotification({ type: "error", message: "Please upload file" });

      return 0;
    }

    const formData = new FormData();

    formData.append("name", payload.name);
    formData.append("file", selectedFile);

    try {
      const response = await fetch("https://api.matix.app/upload", {
        method: "POST",
        body: formData,
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data.message === "Successfully uploaded file") {
        setNotification({
          type: "success",
          message: data.message,
        });

        forceCloseDrawer(true);
        refetchUploads();
      } else {
        setNotification({
          type: "error",
          message: data.message || "Something went wrong",
        });
      }
    } catch (e) {
      setNotification({ type: "error", message: "Something went wrong" });
    }
  };

  return (
    <form className="flex flex-col gap-6" onSubmit={handleAdd}>
      {inputs.map((input, idx) => (
        <Input
          key={idx}
          label={input.label}
          placeholder={input.label}
          defaultValue={payload[input.key]}
          onChange={(text) =>
            setPayload((prevState) => ({ ...prevState, [input.key]: text }))
          }
        />
      ))}

      <Dropzone
        onChange={(e) => setSelectedFile(e.target.files[0])}
        selectedFile={selectedFile}
      />

      <Button
        title="Upload document"
        className="text-indigo-600 border-indigo-600 hover:text-indigo-500 hover:border-indigo-500"
        onClick={handleAdd}
      />
    </form>
  );
};

const Add = ({ refetchUploads }) => {
  const [forceClose, setForceClose] = useState(false);

  const navigate = useNavigate();

  const closeDrawer = () => {
    navigate("/upload");
  };

  return (
    <Drawer
      active={true}
      onClose={closeDrawer}
      title="Upload document"
      forceClose={forceClose}
      setForceClose={setForceClose}
      component={
        <UploadDrawer refetchUploads={refetchUploads} forceCloseDrawer={setForceClose} />
      }
    />
  );
};

export default Add;
