import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SwitchVerticalIcon } from "@heroicons/react/outline";

import Pagination from "./components/pagination";
import Spinner from "common/components/spinner";

function classNames(...props) {
	return props.join(" ");
}

export default function Table({
	header = [],
	data = [],
	actionsToLeft,
	setCurrentPageItems = () => {},
	currentPageItems = [],
	actionsLoading,
	actionsLoadingId,
	actions = [],
	page,
	pageParam = "page",
	loading = false,
	total,
	perPage = 10,
	frontPagination = false,
	search = "",
	allowRerender = false,
}) {
	const [customPage, setCustomPage] = useState(0);
	const [customTotal, setCustomTotal] = useState(0);
	const [renderData, setRenderData] = useState([]);
	const [oldCount, setOldCount] = useState(data.length);

	useEffect(() => {
		if (
			(!renderData.length && currentPageItems.length) ||
			(renderData.length && !currentPageItems.length) ||
			currentPageItems[0]?.id !== renderData[0]?.id
		) {
			setCurrentPageItems(renderData || []);
		}
	}, [renderData]);

	useEffect(() => {
		if (customPage === 1) {
			filterData();
		} else {
			setCustomPage(1);
		}
	}, [search]);

	useEffect(() => {
		if (frontPagination && allowRerender && oldCount !== data.length) {
			setRenderData(data);
		}

		setOldCount(data.length);
	}, [data]);

	useEffect(() => {
		if (!renderData.length || !frontPagination) {
			if (frontPagination && data.length) {
				if (customPage === 1) {
					filterData();
				} else {
					setCustomPage(1);
				}
			} else {
				setRenderData(data);
			}
		}
	}, [data]);

	useEffect(() => {
		filterData();
	}, [customPage]);

	const filterData = () => {
		if (frontPagination) {
			const filtered = data.filter((item) =>
				item?.name?.toLowerCase().includes(search?.toLowerCase())
			);
			const rd = filtered.slice((customPage - 1) * 10, (customPage - 1) * 10 + 10);
			setRenderData(rd);

			setCustomTotal(filtered.length);
		}
	};

	const onPageChange = (page) => {
		if (frontPagination) {
			setCustomPage(page);
		}
	};

	const location = useLocation();

	const searchParams = new URLSearchParams(location.search);

	const headerClicked = (header) => {
		if (header.onClick) {
			header.onClick(header.key);
		}
	};

	return (
		<div className="flex flex-col rounded-md border overflow-hidden">
			<div className="overflow-x-auto">
				<div className="inline-block min-w-full align-middle">
					<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
						<table className="overflow-x-auto min-w-full divide-y divide-gray-300">
							<thead className="bg-gray-50">
								<tr>
									{actions.length && actionsToLeft ? (
										<th
											scope="col"
											className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
										></th>
									) : null}
									{header.map((obj) => (
										<th
											key={obj.key}
											scope="col"
											className={classNames(
												"py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6 whitespace-nowrap",
												obj.sortable ? "cursor-pointer hover:bg-gray-100" : ""
											)}
											onClick={() => headerClicked(obj)}
										>
											<span className="flex items-center">
												{obj.value}
												{obj.sortable ? (
													<SwitchVerticalIcon className="h-3 w-3 ml-3" />
												) : null}
											</span>
										</th>
									))}
									{actions.length && !actionsToLeft ? (
										<th
											scope="col"
											className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
										></th>
									) : null}
								</tr>
							</thead>
							<tbody className="divide-y divide-gray-200 bg-white">
								{loading ? (
									<tr>
										<td className="table-cell w-full" colSpan={header.length}>
											<div className="mx-auto flex items-center justify-center py-10">
												<Spinner className="w-5 h-5" />
											</div>
										</td>
									</tr>
								) : (
									renderData.map((info, idx) => (
										<tr key={info.id || idx}>
											{actions.length && actionsToLeft ? (
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 float-right">
													{info.renderActions ? (
														actionsLoading && actionsLoadingId === info.id ? (
															<Spinner className="w-5 h-5" />
														) : (
															actions.map((action, idx) => {
																if (
																	info.filterActions &&
																	action[info.filterActions] === info[info.filterActions]
																) {
																	return null;
																}
																return (
																	<action.icon
																		className={`w-5 h-5 cursor-pointer inline mx-2`}
																		onClick={() => action.onClick(info)}
																		color={action.color || action.color2}
																		key={`action-${idx}`}
																	/>
																);
															})
														)
													) : null}
												</td>
											) : null}
											{header.map((obj, idx) => (
												<td
													key={`td-${obj.key}`}
													className="whitespace-pre py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
												>
													{info[obj.key]}
												</td>
											))}
											{actions.length && !actionsToLeft ? (
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 float-right">
													{info.renderActions ? (
														actionsLoading && actionsLoadingId === info.id ? (
															<Spinner className="w-5 h-5" />
														) : (
															actions.map((action, idx) => {
																if (
																	info.filterActions &&
																	action[info.filterActions] === info[info.filterActions]
																) {
																	return null;
																}
																return (
																	<action.icon
																		className={`w-5 h-5 cursor-pointer inline mx-2`}
																		onClick={() => action.onClick(info)}
																		color={action.color || action.color2}
																		key={`action-${idx}`}
																	/>
																);
															})
														)
													) : null}
												</td>
											) : (
												""
											)}
										</tr>
									))
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div className="overflow-hidden">
				<Pagination
					current={
						frontPagination
							? customPage
							: parseInt(page, 10) || parseInt(searchParams.get(pageParam), 10) || 1
					}
					total={frontPagination ? customTotal : total}
					perPage={perPage}
					onPageChange={onPageChange}
					pageParam={pageParam}
				/>
			</div>
		</div>
	);
}
