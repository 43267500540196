import { gql } from "@apollo/client";

export const GET_ALL_REWARDS = gql`
  query GetAllRewards(
    $filter: RewardWhereInput
    $userId: ID
    $page: Int
    $sortBy: SortByInput
  ) {
    getAllRewards(filter: $filter, userId: $userId, page: $page, sortBy: $sortBy) {
      total
      rewards {
        id
        ip
        isProxy
        name
        createdAt
        user {
          id
          username
        }
        provider
        amount
        payout
				countryCode
        status
      }
    }
  }
`;
