import { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { NotificationContext } from "context/notification";

import { ADD_OFFERWALL } from "api/mutations/offerwall";

import Select from "common/components/select";
import Drawer from "common/components/drawer";
import Input from "common/components/input";
import Switch from "common/components/switch";
import Button from "common/components/button";

const chargebackSelect = [
  {
    id: 1,
    value: "Status Code",
    key: "statusCode",
  },
  {
    id: 2,
    value: "Negative Amount",
    key: "negativeAmount",
  },
];

const inputs = [
  {
    label: "Name",
    key: "name",
  },
  {
    label: "Priority",
    key: "priority",
  },
  {
    label: "Image",
    key: "image",
  },
  {
    label: "Iframe",
    key: "iframe",
  },
  {
    label: "Postback whitelist",
    key: "whitelist",
  },
  {
    label: "Chargeback completed code",
    key: "completedStatusCode",
  },
  {
    label: "Chargeback rejected code",
    key: "rejectedStatusCode",
  },
  {
    label: "Sticker",
    key: "sticker",
  },
  {
    label: "Countries (separated by comma)",
    key: "countries",
  },
  {
    label: "Level Restriction",
    key: "levelRestriction",
  },
];

const OfferwallDrawer = ({ forceCloseDrawer, refetchOfferwalls }) => {
  const [payload, setPayload] = useState({});
  const [popular, setPopular] = useState();
  const [hasSurveys, setHasSurveys] = useState();
  const [active, setActive] = useState();
  const [highEarning, setHighEarning] = useState();
  const [hideFromProxy, setHideFromProxy] = useState();

  const { setNotification } = useContext(NotificationContext);

  const [addOfferwall, { loading }] = useMutation(ADD_OFFERWALL, {
    onCompleted: (d) => {
      setNotification({
        type: "success",
        message: "Successfully added",
      });
      forceCloseDrawer(true);
      refetchOfferwalls();
    },
    onError: (d) => {
      if (d?.message) {
        setNotification({
          type: "error",
          message: d.message,
        });
      } else {
        setNotification({
          type: "error",
          message: "Something went wrong",
        });
      }
    },
  });

  const handleAdd = () => {
    addOfferwall({
      variables: {
        payload: {
          name: payload.name,
          priority: parseInt(payload.priority, 10),
          levelRestriction: parseInt(payload.levelRestriction, 10),
          image: payload.image,
          iframe: payload.iframe,
          sticker: payload.sticker,
          chargebackType: payload.chargebackType,
          completedStatusCode: payload.completedStatusCode,
          rejectedStatusCode: payload.rejectedStatusCode,
          whitelist: payload.whitelist,
          isPopular: popular,
          highEarning,
          hasSurveys,
          active,
          hideFromProxy,
        },
      },
    });
  };

  return (
    <div className="flex flex-col gap-6">
      {inputs.map((input, idx) => (
        <Input
          key={idx}
          label={input.label}
          placeholder={input.label}
          defaultValue={payload[input.key]}
          onChange={(text) =>
            setPayload((prevState) => ({ ...prevState, [input.key]: text }))
          }
        />
      ))}

      <Select
        label="Chargeback Type"
        data={chargebackSelect}
        onChange={(item) => {
          setPayload((prevState) => ({
            ...prevState,
            chargebackType: item.key,
          }));
        }}
      />

      <Switch enabled={popular} setEnabled={setPopular} label="Popular" />
      <Switch enabled={hasSurveys} setEnabled={setHasSurveys} label="Has surveys" />
      <Switch enabled={highEarning} setEnabled={setHighEarning} label="High earning" />
      <Switch enabled={active} setEnabled={setActive} label="Active" />
      <Switch
        enabled={hideFromProxy}
        setEnabled={setHideFromProxy}
        label="Hide from Proxy"
      />

      <Button
        title="Add offerwall"
        className="text-indigo-600 border-indigo-600 hover:text-indigo-500 hover:border-indigo-500"
        onClick={handleAdd}
      />
    </div>
  );
};

const Add = ({ refetchOfferwalls }) => {
  const [forceClose, setForceClose] = useState(false);

  const navigate = useNavigate();

  const closeDrawer = () => {
    navigate("/offerwalls");
  };

  return (
    <Drawer
      active={true}
      onClose={closeDrawer}
      title="Add Offerwall"
      forceClose={forceClose}
      setForceClose={setForceClose}
      component={
        <OfferwallDrawer
          refetchOfferwalls={refetchOfferwalls}
          forceCloseDrawer={setForceClose}
        />
      }
    />
  );
};

export default Add;
