import { gql } from "@apollo/client";

export const GET_PREVIOUS_ADMIN_MESSAGES = gql`
  query getPreviousAdminMessages {
    getPreviousAdminMessages {
      id
      name
      avatar
      hasUnreadMessages
    }
  }
`;

export const GET_PRIVATE_MESSAGES = gql`
  query getPrivateMessages($userId: ID) {
    getPrivateMessages(userId: $userId) {
      id
      name
      avatar
      level
      message
      createdAt
      type
    }
  }
`;
