import { Fragment, useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import {
	BellIcon,
	BriefcaseIcon,
	ChatIcon,
	CogIcon,
	DocumentSearchIcon,
	HomeIcon,
	MenuAlt2Icon,
	QuestionMarkCircleIcon,
	UsersIcon,
	XIcon,
} from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";

import { NotificationContext } from "context/notification";

import { GET_SETTINGS } from "api/query/get-settings";
import { UPDATE_SETTINGS } from "api/mutations/update-settings";

import Switch from "common/components/switch";
import Input from "common/components/input";
import Button from "common/components/button";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const inputs = {
	basic: [
		{
			title: "Currency",
			placeholder: "Coin",
			key: "currency",
			description: "Currency of the website",
		},
		{
			title: "Point Conversion",
			placeholder: "1000",
			key: "pointsConversion",
			description: "",
		},
		{
			title: "Withdrawal Limit",
			key: "withdrawalLimit",
			description: "",
			placeholder: "500",
			prefix: "$",
		},
		{
			title: "Daily Bonus",
			key: "dailyBonus",
			description: "",
			placeholder: "0",
		},
		{
			title: "Leaderboard Bonus",
			key: "leaderboardPrizes",
			description: "",
			placeholder: "3000,2000,1000,800,700,500,500,500,500,500",
		},
		{
			title: "Promoter Registration Bonus",
			key: "promoterRegistrationBonus",
			description: "",
			placeholder: "0",
		},
		{
			title: "Referral Percentage",
			key: "referralPercent",
			description: "Referral earning percentage.",
			prefix: "%",
			placeholder: "0",
		},
		{
			title: "Reward Limit",
			key: "rewardLimit",
			description: "",
			placeholder: "0",
		},
		{
			title: "Front Domain",
			key: "frontDomain",
			description: "Frontend Domain link",
		},
	],
	advanced: [
		{
			title: "Fraud Check Limit (Points)",
			key: "fraudCheckLevel",
			description: "",
			placeholder: "0",
		},
		{
			title: "Proxycheck.io key",
			key: "proxyCheckKey",
			description: "",
		},
		{
			title: "IpQuality key",
			key: "ipQualityKey",
			description: "",
		},
		{
			title: "Tremendous Key",
			key: "tremendousKey",
			description: "",
		},
		{
			title: "Tremendous Link",
			key: "tremendousLink",
			description: "",
		},
		{
			title: "Coinbase Api Key",
			key: "coinbaseApiKey",
			description: "",
		},
		{
			title: "Coinbase Api Secret",
			key: "coinbaseApiSecret",
			description: "",
		},
		{
			title: "Coinbase Link",
			key: "coinbaseLink",
			description: "",
		},
		{
			title: "Google Client ID",
			key: "googleClientId",
			description: "",
		},
		{
			title: "Mailgun Key",
			key: "mailgunKey",
			description: "",
		},
		{
			title: "Mailgun Domain",
			key: "mailgunDomain",
			description: "",
		},
		{
			title: "Blockio Pin",
			key: "blockioPin",
			description: "",
		},
		{
			title: "Blockio Bitcoin Api Key",
			key: "blockioApiBtc",
			description: "",
		},
		{
			title: "Blockio Litecoin Api Key",
			key: "blockioApiLtc",
			description: "",
		},
		{
			title: "Blockio Dogecoin Api Key",
			key: "blockioApiDoge",
			description: "",
		},
	],
	badges: [
		{
			title: "Leaderboard Bonus Badge",
			key: "leaderboardBonusBadge",
			description: "",
		},
	],
};

export default function OverviewStatistics() {
	const [settings, setSettings] = useState({
		basic: {
			currency: "",
			withdrawalLimit: "",
		},
		advanced: {
			fraudCheckLevel: "",
		},
		badges: {
			leaderboardBonusBadge: "",
		},
	});
	const [activeTab, setActiveTab] = useState("basic");

	const { setNotification } = useContext(NotificationContext);

	const { data: { getSettings: settingsData } = {}, refetch: refetchSettings } =
		useQuery(GET_SETTINGS);

	const [updateSettingsMutation, { loading }] = useMutation(UPDATE_SETTINGS, {
		onCompleted: (d) => {
			setNotification({
				type: "success",
				message: "Successfully updated",
			});
			refetchSettings();
		},
		onError: (d) => {
			setNotification({
				type: "error",
				message: "Something went wrong",
			});
		},
	});

	useEffect(() => {
		if (settingsData) {
			setSettings({
				basic: [
					"currency",
					"pointsConversion",
					"withdrawalLimit",
					"dailyBonus",
					"leaderboardPrizes",
					"promoterRegistrationBonus",
					"rewardLimit",
					"referralPercent",
					"frontDomain",
				].reduce((acc, curr) => {
					acc[curr] = settingsData[curr];

					return acc;
				}, {}),
				advanced: [
					"fraudCheckLevel",
					"proxyCheckKey",
					"ipQualityKey",
					"tremendousKey",
					"tremendousLink",
					"coinbaseApiKey",
					"coinbaseApiSecret",
					"coinbaseLink",
					"googleClientId",
					"mailgunKey",
					"mailgunDomain",
					"blockioPin",
					"blockioApiBtc",
					"blockioApiLtc",
					"blockioApiDoge",
				].reduce((acc, curr) => {
					acc[curr] = settingsData[curr];

					return acc;
				}, {}),
				badges: ["leaderboardBonusBadge"].reduce((acc, curr) => {
					acc[curr] = settingsData[curr];

					return acc;
				}, {}),
			});
		}
	}, [settingsData]);

	const tabs = [
		{
			name: "Basic",
			current: activeTab === "basic",
			onClick: () => {
				if (activeTab !== "basic") {
					setActiveTab("basic");
				}
			},
		},
		{
			name: "Advanced",
			current: activeTab === "advanced",
			onClick: () => {
				if (activeTab !== "advanced") {
					setActiveTab("advanced");
				}
			},
		},
		{
			name: "Badges",
			current: activeTab === "badges",
			onClick: () => {
				if (activeTab !== "badges") {
					setActiveTab("badges");
				}
			},
		},
	];

	const updateSettings = () => {
		if (
			Object.keys(settings[activeTab]).some(
				(key) => settingsData[key] != settings[activeTab][key]
			)
		) {
			updateSettingsMutation({
				variables: {
					payload:
						activeTab === "advanced"
							? {
									...settings.advanced,
									fraudCheckLevel: parseInt(settings.advanced.fraudCheckLevel, 10),
							  }
							: activeTab === "badges"
							? { ...settings.badges }
							: {
									...settings.basic,
									referralPercent: parseFloat(settings.basic.referralPercent, 10),
									pointsConversion: parseFloat(settings.basic.pointsConversion, 10),
									withdrawalLimit: parseFloat(settings.basic.withdrawalLimit, 10),
									dailyBonus: parseFloat(settings.basic.dailyBonus, 10),
									leaderboardPrizes: JSON.stringify(
										settings?.basic?.leaderboardPrizes?.split(",")
									),
									promoterRegistrationBonus: parseFloat(
										settings.basic.promoterRegistrationBonus,
										10
									),
									rewardLimit: parseFloat(settings.basic.rewardLimit, 10),
							  },
				},
			});
		}
	};

	return (
		<>
			<div>
				<div>
					<div className="mx-4 flex flex-col">
						<main className="flex-1">
							<div className="relative mx-auto">
								<div className="py-5">
									<div className="px-4 sm:px-6 md:px-0">
										<h1 className="text-3xl font-bold text-gray-700">Settings</h1>
									</div>
									<div className="px-4 sm:px-6 md:px-0">
										<div className="py-6">
											<div className="lg:hidden">
												<label htmlFor="selected-tab" className="sr-only">
													Select a tab
												</label>
												<select
													id="selected-tab"
													name="selected-tab"
													className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
													defaultValue={tabs.find((tab) => tab.current).name}
												>
													{tabs.map((tab) => (
														<option onClick={tab.onClick} key={tab.name}>
															{tab.name}
														</option>
													))}
												</select>
											</div>
											<div className="hidden lg:block">
												<div className="border-b border-gray-200">
													<nav className="-mb-px flex space-x-8">
														{tabs.map((tab) => (
															<span
																key={tab.name}
																className={classNames(
																	tab.current
																		? "border-purple-500 text-purple-600"
																		: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
																	"whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
																)}
																onClick={tab.onClick}
															>
																{tab.name}
															</span>
														))}
													</nav>
												</div>
											</div>

											<div className="mt-10 divide-y divide-gray-200">
												<div className="space-y-1">
													<h3 className="text-lg leading-6 font-medium text-gray-900">
														Global settings
													</h3>
													<p className="max-w-2xl text-sm text-gray-500">
														Manage services
													</p>
												</div>

												<div className="mt-6">
													<dl className="divide-y divide-gray-200">
														{inputs[activeTab].map((input) => (
															<div
																className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5"
																key={input?.key}
															>
																<dt className="text-sm font-medium text-gray-500 flex justify-center flex-col">
																	{input.title}
																	<p className="max-w-2xl text-xs text-gray-400 ml-1">
																		{input.description}
																	</p>
																</dt>
																<dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
																	<span className="flex-grow"></span>
																	<span className="ml-4 flex-shrink-0 flex items-start space-x-4">
																		<Input
																			defaultValue={settings[activeTab][input.key]}
																			placeholder={input.placeholder}
																			prefix={input.prefix}
																			onChange={(text) => {
																				if (settings[activeTab][input.key] != text) {
																					setSettings((prevState) => ({
																						...prevState,
																						[activeTab]: {
																							...prevState[activeTab],
																							[input.key]: text,
																						},
																					}));
																				}
																			}}
																		/>
																	</span>
																</dd>
															</div>
														))}

														<div>
															<Button
																title="Save changes"
																className="mt-2"
																onClick={updateSettings}
															/>
														</div>
													</dl>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</main>
					</div>
				</div>
			</div>
		</>
	);
}
