import { useState, useEffect, useContext, useRef, memo } from "react";
import { useQuery, useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import { useNavigate, Routes, Route, Link, useLocation } from "react-router-dom";
import { SearchIcon, TrashIcon } from "@heroicons/react/outline";

import AddUpload from "pages/upload/components/add";
import Table from "common/components/table";
import Input from "common/components/input";
import Select from "common/components/select";
import Button from "common/components/button";
import Modal from "common/components/modal";

import { NotificationContext } from "context/notification";

import { GET_PREVIOUS_ADMIN_MESSAGES, GET_PRIVATE_MESSAGES } from "api/query/messages";
import { PRIVATE_MESSAGE_CREATED } from "api/subscriptions/messages";
import { SEND_MESSAGE, READ_MESSAGES } from "api/mutations/messages";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Chat = () => {
  const [message, setMessage] = useState("");
  const [allMessages, setMessages] = useState([]);
  const [userId, setUserId] = useState(null);
  const [currentChat, setCurrentChat] = useState({
    id: null,
    name: "",
    avatar: "",
  });

  const scrollRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const { data: { getPreviousAdminMessages: previousMessages } = {} } = useQuery(
    GET_PREVIOUS_ADMIN_MESSAGES
  );

  const [getMessages] = useLazyQuery(GET_PRIVATE_MESSAGES, {
    onCompleted: (d) => {
      if (d?.getPrivateMessages) {
        setMessages(d?.getPrivateMessages);
        setCurrentChat(
          d?.getPrivateMessages?.find((item) => item.type === "receiver") || {}
        );

        if (scrollRef?.current) {
          setTimeout(() => {
            scrollRef?.current?.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
          }, 250);
        }
      }
    },
  });

  const [sendMessageMutation] = useMutation(SEND_MESSAGE, {
    onCompleted: (d) => {
      // Do something
      if (d?.createPrivateMessage?.id) {
        setMessage("");
      }
    },
  });

  const [readMessagesMutation] = useMutation(READ_MESSAGES, {
    onCompleted: (d) => {
      // Do something
    },
  });

  const { data: { privateMessageCreated } = {} } = useSubscription(
    PRIVATE_MESSAGE_CREATED,
    {
      variables: {
        userId,
      },
    }
  );

  useEffect(() => {
    setUserId(searchParams.get("uid"));
  }, []);

  useEffect(() => {
    if (userId) {
      getMessages({
        variables: {
          userId,
        },
      });
    }
  }, [userId]);

  useEffect(() => {
    if (privateMessageCreated?.id) {
      setMessages((prevState) => [...prevState, privateMessageCreated]);

      if (scrollRef?.current) {
        setTimeout(() => {
          scrollRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }, 250);
      }
    }
  }, [privateMessageCreated]);

  const sendMessage = (e) => {
    e.preventDefault();

    sendMessageMutation({
      variables: {
        message,
        userId,
      },
    });
  };

  const readMessages = (senderId) => {
    readMessagesMutation({
      variables: {
        senderId,
      },
    });
  };

  return (
    <div className="mx-4">
      <div className="px-4 py-4 sm:px-6 md:px-0">
        <h1 className="text-3xl font-bold text-gray-700">Chat</h1>
      </div>
      <div className="my-4" />
      <div className="border border-slate-300 h-[650px] grid grid-cols-10">
        <div className="col-span-2 flex flex-col items-center px-2 py-1 gap-1 sm:px-3 sm:py-2 sm:gap-2 lg:px-4 lg:py-2 lg:gap-3 overflow-auto border-r border-slate-300">
          {previousMessages?.map((chat, idx) => {
            if (!chat.id) {
              return null;
            }

            return (
              <div
                key={idx}
                className="w-11/12  min-h-[55px] grid grid-cols-8 cursor-pointer hover:bg-slate-200 rounded-lg border-b border-slate-300 relative"
                onClick={() => {
                  setUserId(chat.id);
                  navigate(`?uid=${chat.id}`);
                  if (chat?.hasUnreadMessages && chat?.id) {
                    readMessages(chat.id);
                  }
                }}
              >
                {/* Previous chat */}
                <div className="col-span-8 lg:col-span-2 flex items-center justify-center">
                  <img
                    src={chat?.avatar}
                    alt={chat?.name?.[0]}
                    className="aspect-square h-5/6 rounded-full bg-gray-200 flex justify-center items-center"
                  />
                </div>
                <div className="col-span-6 hidden items-center ml-2 py-2 lg:flex">
                  <p className="text-sm max-w-[80%]">
                    {chat?.name?.length > 10
                      ? chat?.name?.slice(0, 10) + "..."
                      : chat?.name}
                  </p>
                </div>
                {chat?.hasUnreadMessages && (
                  <div className="bg-red-500 w-[7px] h-[7px] rounded-full absolute right-2 top-1/2 -translate-y-1/2"></div>
                )}
              </div>
            );
          })}
        </div>
        <div className="h-[650px] col-span-8">
          <div className="h-[10%] flex items-center ml-2 gap-3 border-b border-slate-300">
            {/* Information about current user */}
            <img
              src={currentChat?.avatar}
              alt={currentChat?.name?.[0]}
              className="w-8 h-8 rounded-full bg-gray-200 flex justify-center items-center"
            />
            <h2 className="text-xl text-gray-500">{currentChat?.name}</h2>
          </div>
          <div className="h-[80%] overflow-auto border-b border-slate-300">
            {/* Render messages */}
            {allMessages?.map((data = {}, idx) => {
              return (
                <div
                  key={idx}
                  className={`w-[98%] m-2 flex ${
                    data?.type === "sender" ? "flex-row-reverse" : ""
                  }`}
                >
                  <div
                    className={`${
                      data?.type === "sender" ? "bg-cyan-100" : "bg-slate-200"
                    } rounded-lg px-3 py-2 text-sm max-w-[60%]`}
                  >
                    <p>{data.message}</p>
                  </div>
                </div>
              );
            })}
            <div ref={scrollRef} />
          </div>
          <form className="h-[10%] relative mt-2" onSubmit={sendMessage}>
            {/* Render input */}
            <Input
              placeholder="Type message..."
              className="absolute inset-0 w-[98%] mx-auto"
              onChange={(text) => setMessage(text)}
              value={message}
              sufix={
                <img
                  className="cursor-pointer px-2 w-9 aspect-square"
                  src="/icons/send.svg"
                  alt="SEND"
                  onClick={sendMessage}
                />
              }
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default memo(Chat);
