import { gql } from "@apollo/client";

export const UPDATE_USER = gql`
  mutation UpdateUser($payload: UpdateUserInput, $userId: ID!) {
    updateUser(payload: $payload, userId: $userId)
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
