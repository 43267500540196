import { useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate, Routes, Route, Link } from "react-router-dom";
import { CheckIcon, XIcon, PlusCircleIcon } from "@heroicons/react/outline";

import { PencilIcon } from "@heroicons/react/outline";

import { NotificationContext } from "context/notification";

import EditQuestion from "./components/edit";
import AddQuestion from "./components/add";

import Drawer from "common/components/drawer";
import Card from "common/components/card";
import Modal from "common/components/modal";
import Button from "common/components/button";

import { GET_ALL_QUESTIONS } from "api/query/get-all-questions";
import { DELETE_QUESTION } from "api/mutations/question";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const getIcon = (state) => {
  return state ? (
    <CheckIcon className="w-5 h-5 ml-[50%] -translate-x-1/2" color="#22c55e" />
  ) : (
    <XIcon className="w-5 h-5 ml-[50%] -translate-x-1/2" color="#ef4444" />
  );
};

export default function Questions({ setSidebarOpen }) {
  const [modalActive, setModalActive] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { setNotification } = useContext(NotificationContext);
  const navigate = useNavigate();

  const { data: { getAllQuestions: questions } = {}, refetch: refetchQuestions } =
    useQuery(GET_ALL_QUESTIONS, {});

  const [deleteQuestionMutation, { loading }] = useMutation(DELETE_QUESTION, {
    onCompleted: (d) => {
      setNotification({
        type: "success",
        message: "Successfully deleted",
      });
      refetchQuestions();
    },
    onError: (d) => {
      setNotification({
        type: "error",
        message: "Something went wrong",
      });
    },
  });

  const onEdit = (question) => {
    navigate(`edit/${question.id}`);
  };

  const deleteQuestion = (id) => {
    if (id !== null) {
      deleteQuestionMutation({
        variables: {
          id: id,
        },
      });
    }
  };

  const onDelete = (question) => {
    setDeleteId(question.id);
    setModalActive(true);
  };

  const onModalClose = () => {
    setDeleteId(null);
  };

  const addQuestionDrawer = () => {
    navigate("add");
  };

  const generateDesc = (question) => {
    return (
      <div>
        <p></p>
      </div>
    );
  };

  const generateDescription = (question = {}) => {
    return (
      <div className="text-xs text-gray-500">
        <p className="break-words">
          Title: <span className="text-gray-400">{question.question}</span>
        </p>
        <p className="mt-2 break-words">
          Description: <span className="text-gray-400">{question.answer}</span>
        </p>
      </div>
    );
  };

  return (
    <div className="mx-4">
      <Routes>
        <Route
          path="/edit/:id"
          element={<EditQuestion refetchQuestions={refetchQuestions} />}
        />
        <Route
          path="/add"
          element={<AddQuestion refetchQuestions={refetchQuestions} />}
        />
      </Routes>
      <Modal
        active={modalActive}
        setActive={setModalActive}
        title="Are you sure?"
        message="You are about to delete an Question"
        onSubmit={() => deleteQuestion(deleteId)}
      />
      <div className="px-4 py-4 sm:px-6 md:px-0">
        <h1 className="text-3xl font-bold text-gray-700">Questions</h1>
      </div>
      <div className="my-4 flex flex-wrap gap-4">
        {questions?.map((question, idx) => (
          <Card
            key={idx}
            hideImage={true}
            description={generateDescription(question)}
            onEdit={() => onEdit(question)}
            onDelete={() => onDelete(question)}
          />
        ))}

        <div
          className="shadow bg-white min-h-[200px] w-[210px] max-h-[300px] rounded-md overflow-hidden relative mx-auto xs:mx-0 cursor-pointer flex justify-center items-center flex-col gap-1"
          onClick={addQuestionDrawer}
        >
          <PlusCircleIcon className="w-8 h-8 text-gray-400" />
          <span className="text-xs text-gray-400">Add new</span>
        </div>
      </div>
    </div>
  );
}
