import { gql } from "@apollo/client";

export const GET_SETTINGS = gql`
  query GetSettings {
    getSettings {
      currency
      pointsConversion
      referralPercent
      withdrawalLimit
      fraudCheckLevel
      promoterRegistrationBonus
      dailyBonus
      leaderboardPrizes
      rewardLimit
      proxyCheckKey
      ipQualityKey
      frontDomain
      tremendousKey
      tremendousLink
      coinbaseApiKey
      coinbaseApiSecret
      coinbaseLink
      googleClientId
      mailgunKey
      mailgunDomain
      blockioPin
      blockioApiBtc
      blockioApiLtc
      blockioApiDoge
    }
  }
`;
