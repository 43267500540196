import { gql } from "@apollo/client";

export const GET_ALL_COUNTRIES = gql`
  query GetAllCountries($page: Int, $sortBy: SortByInput) {
    getAllCountries(page: $page, sortBy: $sortBy) {
      total
      countries {
        id
        name
        code
        totalUsers
        blocked
        fraudCheck
        minValueFraudCheck
      }
    }
  }
`;
