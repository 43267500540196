import { useState } from "react";
import { useQuery } from "@apollo/client";

import { GET_ME } from "api/query/get-me";

export const withAuth = (Component) => () => {
  const [isAuthorised, setAuthorised] = useState(false);

  const { data } = useQuery(GET_ME, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (d) => {
      if (d?.me && (d?.me?.isAdmin || d?.me?.isEditor)) {
        setAuthorised(true);
      } else {
        window.location.href = "/login";
      }
    },
    onError: (d) => {
      window.location.href = "/login";
    },
  });

  return isAuthorised ? <Component /> : null;
};
