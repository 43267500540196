import { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { NotificationContext } from "context/notification";

import { CREATE_TASK } from "api/mutations/tasks";

import Drawer from "common/components/drawer";
import Input from "common/components/input";
import Button from "common/components/button";
import Select from "common/components/select";

const inputs = [
	{ label: "Title", key: "title" },
	{ label: "Description", key: "description" },
	{ label: "Amount to reach", key: "amount" },
	{ label: "Prize in points", key: "prize" },
	{ label: "Priority", key: "priority" },
];

const TaskDrawer = ({ forceCloseDrawer, refetchTasks }) => {
	const [payload, setPayload] = useState({});
	const [key, setKey] = useState("referFriend");

	const { setNotification } = useContext(NotificationContext);

	const [addTask, { loading }] = useMutation(CREATE_TASK, {
		onCompleted: (d) => {
			setNotification({
				type: "success",
				message: "Successfully added",
			});
			forceCloseDrawer(true);
			refetchTasks();
		},
		onError: (d) => {
			if (d?.message) {
				setNotification({
					type: "error",
					message: d.message,
				});
			} else {
				setNotification({
					type: "error",
					message: "Something went wrong",
				});
			}
		},
	});

	const handleAdd = () => {
		addTask({
			variables: {
				payload: {
					...payload,
					amount: parseInt(payload.amount),
					prize: parseInt(payload.prize),
					priority: parseInt(payload.priority),
					key,
				},
			},
		});
	};

	return (
		<div className="flex flex-col gap-6">
			{inputs.map((input, idx) => (
				<Input
					key={idx}
					label={input.label}
					placeholder={input.label}
					defaultValue={payload[input.key]}
					onChange={(text) =>
						setPayload((prevState) => ({ ...prevState, [input.key]: text }))
					}
				/>
			))}

			<Select
				label="Select key"
				defaultValue={{ referFriend: 0, earnPoints: 1, reachLevel: 2 }[key]}
				onChange={(key) => setKey(key.id)}
				data={[
					{ id: "referFriend", value: "Refer Friend" },
					{ id: "earnPoints", value: "Earn Points" },
					{ id: "reachLevel", value: "Reach Level" },
				]}
			/>

			<Button
				title="Add Task"
				className="text-indigo-600 border-indigo-600 hover:text-indigo-500 hover:border-indigo-500"
				onClick={handleAdd}
			/>
		</div>
	);
};

const Add = ({ refetchTasks }) => {
	const [forceClose, setForceClose] = useState(false);

	const navigate = useNavigate();

	const closeDrawer = () => {
		navigate("/tasks");
	};

	return (
		<Drawer
			active={true}
			onClose={closeDrawer}
			title="Add Task"
			forceClose={forceClose}
			setForceClose={setForceClose}
			component={
				<TaskDrawer refetchTasks={refetchTasks} forceCloseDrawer={setForceClose} />
			}
		/>
	);
};

export default Add;
