import { useState, useEffect, useContext, memo } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import ReactCountryFlag from "react-country-flag";
import Cookie from "nookies";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { SearchIcon, TrashIcon } from "@heroicons/react/outline";

import { MATIX_TOKEN } from "common/constants";

import Table from "common/components/table";
import Input from "common/components/input";
import Select from "common/components/select";
import Button from "common/components/button";
import Modal from "common/components/modal";

import { NotificationContext } from "context/notification";

import { GET_ALL_BACKUPS } from "api/query/get-all-backups";
import { GET_BACKUP } from "api/query/get-backup";
import { DELETE_BACKUP } from "api/mutations/backup";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const header = [
  {
    key: "name",
    value: "NAME",
  },
  {
    key: "link",
    value: "LINK",
  },
];

const Backup = ({ setSidebarOpen }) => {
  const [search, setSearch] = useState("");
  const [modalActive, setModalActive] = useState(false);
  const [deleteBackupName, setDeleteBackup] = useState();
  const [currentModal, setCurrentModal] = useState("");
  const [backupInfo, setBackupInfo] = useState({});

  const { setNotification } = useContext(NotificationContext);

  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const { data: { getAllBackups = [] } = {}, refetch: refetchBackups } =
    useQuery(GET_ALL_BACKUPS);

  const [getBackupQuery] = useLazyQuery(GET_BACKUP, {
    onCompleted: async (d) => {
      const link = d?.getBackup;

      if (link) {
        const token = Cookie.get(MATIX_TOKEN)[MATIX_TOKEN];

        const res = await fetch(link, {
          method: "POST",
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        const blob = await res.blob();

        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);

        a.setAttribute("download", "backup.tar");
        a.click();
      }
    },
    onError: (e) => {
      setNotification({
        type: "error",
        message: "Error occurred while getting database backup",
      });
    },
  });

  const [deleteBackupMutation] = useMutation(DELETE_BACKUP, {
    onCompleted: (d) => {
      if (d?.deleteBackup) {
        setNotification({
          type: "success",
          message: "Successfully deleted",
        });
        refetchBackups();
      } else {
        setNotification({
          type: "error",
          message: "Something went wrong",
        });
      }
    },
    onError: (d) => {
      setNotification({
        type: "error",
        message: "Something went wrong",
      });
    },
  });

  const actions = [
    {
      icon: TrashIcon,
      onClick: (backup = {}) => {
        setCurrentModal("deleteUpload");
        setDeleteBackup(backup.name);
        setModalActive(true);
      },
      color: "#ef4444",
    },
  ];

  const searchUploads = (data = "") => {
    setSearch(data);
  };

  const modalInputs = [
    <Input
      key="0"
      label="Username"
      placeholder="Username"
      onChange={(text) => setBackupInfo((prev) => ({ ...prev, username: text }))}
    />,
    <Input
      key="1"
      type="password"
      label="Password"
      placeholder="Password"
      onChange={(text) => setBackupInfo((prev) => ({ ...prev, password: text }))}
    />,
  ];

  const getBackup = () => {
    if (backupInfo.username && backupInfo.password) {
      getBackupQuery({
        variables: {
          payload: backupInfo,
        },
      });

      setNotification({
        type: "success",
        message: "Download will start in few moments...",
      });
    }
  };

  const deleteBackup = () => {
    deleteBackupMutation({
      variables: {
        name: deleteBackupName,
      },
    });
  };

  const downloadBackup = async (link, name) => {
    const token = Cookie.get(MATIX_TOKEN)[MATIX_TOKEN];

    const res = await fetch(link, {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    const blob = await res.blob();

    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);

    a.setAttribute("download", name);
    a.click();
  };

  return (
    <div className="mx-4">
      <Modal
        active={modalActive}
        setActive={setModalActive}
        inputs={currentModal === "generateBackup" ? modalInputs : []}
        title={currentModal === "generateBackup" ? "Database backup" : "Are you sure?"}
        message={
          currentModal === "generateBackup"
            ? `Please enter username and password of database`
            : `You are going to delete backup with name ${deleteBackupName}`
        }
        onSubmit={currentModal === "generateBackup" ? getBackup : deleteBackup}
      />
      <div className="px-4 py-4 sm:px-6 md:px-0">
        <h1 className="text-3xl font-bold text-gray-700">Backup</h1>
      </div>
      <div className="my-4 flex justify-between flex-col lg:flex-row gap-4">
        <Input
          placeholder="Search"
          onChange={searchUploads}
          prefix={<SearchIcon className="w-4 h-4" />}
          className="max-w-[200px]"
        />

        <Button
          title="Get database backup"
          onClick={() => {
            setCurrentModal("generateBackup");
            setModalActive(true);
          }}
        />
      </div>
      <div className="my-4" />
      <Table
        header={header}
        data={getAllBackups?.map((backup) => ({
          ...backup,
          link: (
            <span
              className="text-blue-600 cursor-pointer"
              onClick={() => downloadBackup(backup.link, backup.name)}
            >
              {backup.link}
            </span>
          ),
          renderActions: true,
        }))}
        actions={actions}
        search={search}
        frontPagination={true}
        perPage={10}
        allowRerender={true}
      />
    </div>
  );
};

export default memo(Backup);
