import { gql } from "@apollo/client";

export const SEND_MESSAGE = gql`
  mutation CreatePrivateMessage($message: String!, $userId: ID) {
    createPrivateMessage(message: $message, userId: $userId) {
      id
    }
  }
`;

export const READ_MESSAGES = gql`
  mutation ReadMessages($senderId: ID!) {
    readMessages(senderId: $senderId)
  }
`;
