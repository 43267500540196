import { useState, useContext, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { CheckIcon, XIcon, SearchIcon } from "@heroicons/react/outline";

import { NotificationContext } from "context/notification";

import { getStatus } from "utilities";

import Table from "common/components/table";
import Input from "common/components/input";
import Select from "common/components/select";
import Button from "common/components/button";
import Modal from "common/components/modal";
import Spinner from "common/components/spinner";

import { GET_ALL_WITHDRAWALS } from "api/query/get-all-withdrawals";

import {
	APPROVE_PENDING_WITHDRAWALS,
	REJECT_PENDING_WITHDRAWALS,
} from "api/mutations/change-withdrawal-status";
import { CHANGE_WITHDRAWAL_STATUS } from "api/mutations/change-withdrawal-status";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export const header = [
	{
		key: "id",
		value: "ID",
		sortable: true,
	},
	{
		key: "createdAt",
		value: "DATE",
		sortable: true,
	},
	{
		key: "username",
		value: "USERNAME",
		sortable: true,
	},
	{
		key: "withdrawalMethod",
		value: "WITHDRAWAL METHOD",
		sortable: true,
	},
	{
		key: "amount",
		value: "AMOUNT",
		sortable: true,
	},
	{
		key: "payout",
		value: "PAYOUT",
	},
	{
		key: "paymentInfo",
		value: "PAYMENT INFO",
		sortable: true,
	},
	{
		key: "paymentDetails",
		value: "PAYMENT DETAILS",
		sortable: true,
	},
	{
		key: "transactionHex",
		value: "TRANSACTION HEX",
	},
	{
		key: "status",
		value: "STATUS",
		sortable: true,
	},
];

export const filterWithdrawalsSelect = [
	{
		id: 1,
		value: "All",
	},
	{
		id: 2,
		value: "Completed",
		status: 1,
	},
	{
		id: 3,
		value: "Pending",
		status: 0,
	},
	{
		id: 4,
		value: "Rejected",
		status: 2,
	},
];

export default function OverviewStatistics({ setSidebarOpen }) {
	const [filters, setFilters] = useState({});
	const [modalActive, setModalActive] = useState(false);
	const [modalInfo, setModalInfo] = useState({});
	const [page, setPage] = useState(1);
	const [sortBy, setSortBy] = useState({});
	const [data, setData] = useState([]);
	const [totalWithdrawals, setTotal] = useState(0);
	const [loadingId, setLoadingId] = useState(null);

	const { setNotification } = useContext(NotificationContext);
	const location = useLocation();

	const searchParams = new URLSearchParams(location.search);

	const {
		data: { getAllWithdrawals: { withdrawals, total } = {} } = {},
		refetch: refetchWithdrawals,
	} = useQuery(GET_ALL_WITHDRAWALS, {
		variables: {
			filter: filters,
			page,
			sortBy,
		},
	});

	useEffect(() => {
		if (total !== totalWithdrawals && total) {
			setTotal(total);
		}
	}, [total]);

	useEffect(() => {
		if (withdrawals) {
			setData(withdrawals);
		}
	}, [withdrawals]);

	useEffect(() => {
		if (parseInt(searchParams.get("page"), 10) > 0) {
			setPage(parseInt(searchParams.get("page")));
		}
	}, [searchParams.get("page")]);

	const [changeWithdrawalStatus, { loading: withdrawalLoading }] = useMutation(
		CHANGE_WITHDRAWAL_STATUS,
		{
			onCompleted: (d) => {
				if (d?.changeWithdrawalStatus) {
					setNotification({
						type: "success",
						message: "Successfully updated",
					});
					refetchWithdrawals();
				} else {
					setNotification({
						type: "error",
						message: d?.errors?.[0]?.message || "Something went wrong",
					});
				}
			},
			onError: (d) => {
				setNotification({
					type: "error",
					message: d?.message || d?.errors?.[0]?.message || "Something went wrong",
				});
			},
		}
	);

	const [approvePending, { loading: loadingApprove }] = useMutation(
		APPROVE_PENDING_WITHDRAWALS,
		{
			onCompleted: (d) => {
				if (d?.changePendingToCompletedWithdrawals) {
					setNotification({
						type: "success",
						message: "Successfully updated",
					});
					refetchWithdrawals();
				} else {
					setNotification({
						type: "error",
						message: d?.errors?.[0]?.message || "Something went wrong",
					});
				}
			},
			onError: (d) => {
				setNotification({
					type: "error",
					message: d?.message || d?.errors?.[0]?.message || "Something went wrong",
				});
			},
		}
	);

	const [rejectPending] = useMutation(REJECT_PENDING_WITHDRAWALS, {
		onCompleted: (d) => {
			if (d?.changePendingToRejectedWithdrawals) {
				setNotification({
					type: "success",
					message: "Successfully updated",
				});
				refetchWithdrawals();
			} else {
				setNotification({
					type: "error",
					message: d?.errors?.[0]?.message || "Something went wrong",
				});
			}
		},
		onError: (d) => {
			setNotification({
				type: "error",
				message: d?.message || d?.errors?.[0]?.message || "Something went wrong",
			});
		},
	});

	const actions = [
		{
			icon: CheckIcon,
			onClick: (info) => {
				if (withdrawalLoading) {
					setNotification({
						type: "info",
						message: "Already loading",
					});

					return 0;
				}

				setLoadingId(info.id);

				changeModal("You are about to approve withdrawal", () => {
					changeWithdrawalStatus({
						variables: {
							payload: {
								withdrawalId: parseInt(info.id, 10),
								status: 1,
							},
						},
					});
				});
			},
			color: "#22c55e",
		},
		{
			icon: XIcon,
			onClick: (info) => {
				if (withdrawalLoading) {
					setNotification({
						type: "info",
						message: "Already loading",
					});

					return 0;
				}

				setLoadingId(info.id);

				changeModal("You are about to reject withdrawal", () => {
					changeWithdrawalStatus({
						variables: {
							payload: {
								withdrawalId: parseInt(info.id, 10),
								status: 2,
							},
						},
					});
				});
			},
			color: "#ef4444",
		},
	];

	const setSelectFilter = (info) => {
		setFilters((prevState) => ({
			...prevState,
			status: info.status,
		}));
	};

	const approveAllPending = () => {
		approvePending();
	};

	const rejectAllPending = () => {
		rejectPending();
	};

	const sortTableBy = (name) => {
		// Reset page to 1
		setPage(1);

		setSortBy((prevState) => ({
			name,
			type: prevState.type === "DESC" ? "ASC" : "DESC",
		}));
	};

	const searchWithdrawals = (data) => {
		setFilters((prevState) => ({
			...prevState,
			search: data,
		}));
	};

	const changeModal = (message, cb) => {
		setModalInfo({
			message,
			cb,
		});

		setModalActive(true);
	};

	return (
		<div className="mx-4">
			<Modal
				active={modalActive}
				setActive={setModalActive}
				title="Are you sure?"
				message={modalInfo.message}
				onSubmit={modalInfo.cb}
			/>
			<div className="px-4 py-4 sm:px-6 md:px-0">
				<h1 className="text-3xl font-bold text-gray-700">Withdrawals</h1>
			</div>
			<div className="my-4 flex justify-between flex-col lg:flex-row gap-4">
				<div className="flex gap-2 flex-wrap lg:min-w-[450px]">
					<Select
						data={filterWithdrawalsSelect}
						className="max-w-[200px]"
						onChange={setSelectFilter}
					/>

					<Input
						placeholder="Search"
						onChange={searchWithdrawals}
						prefix={<SearchIcon className="w-4 h-4" />}
						className="max-w-[200px]"
					/>
				</div>
				<div className="flex flex-wrap gap-4 justify-end">
					<Button
						onClick={() =>
							changeModal(
								"You are about to reject all pending withdrawals",
								rejectAllPending
							)
						}
						className="max-w-[408px] text-red-600 hover:text-red-500 border-red-600 hover:border-red-500"
						title="Reject all pending withdrawals"
					/>
					<Button
						onClick={() =>
							changeModal(
								"You are about to approve all pending withdrawals",
								approveAllPending
							)
						}
						className="max-w-[408px] flex items-center justify-center"
						startIcon={loadingApprove ? <Spinner className="w-4 h-4" /> : null}
						title="Approve all pending withdrawals"
					/>
				</div>
			</div>
			<Table
				actions={actions}
				actionsLoading={withdrawalLoading}
				actionsLoadingId={loadingId}
				page={page}
				total={totalWithdrawals}
				header={header.map((hdr) => {
					if (hdr.sortable) {
						hdr.onClick = sortTableBy;
					}

					return hdr;
				})}
				data={data?.map((withdrawal) => ({
					...withdrawal,
					renderActions: withdrawal.status == 0,
					status: getStatus(withdrawal.status),
					username: (
						<a
							className="text-blue-600"
							href={`/users?username=${encodeURIComponent(withdrawal?.user?.username)}`}
						>
							{withdrawal?.user?.username}
						</a>
					),
				}))}
			/>
		</div>
	);
}
