import { gql } from "@apollo/client";

export const GET_ALL_ANNOUNCEMENTS = gql`
  query GetAllAnnouncements {
    getAllAnnouncements {
      id
      title
      description
      createdAt
    }
  }
`;
