import { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { NotificationContext } from "context/notification";

import { ADD_REVIEW } from "api/mutations/review";

import Drawer from "common/components/drawer";
import Input from "common/components/input";
import Textarea from "common/components/textarea";
import Button from "common/components/button";

const inputs = [
  {
    label: "Name",
    key: "name",
  },
  {
    label: "Avatar",
    key: "avatar",
  },
  {
    label: "Rate",
    key: "rate",
  },
];

const textareas = [
  {
    label: "Description",
    key: "description",
  },
];

const ReviewDrawer = ({ forceCloseDrawer, refetchReviews }) => {
  const [payload, setPayload] = useState({});

  const { setNotification } = useContext(NotificationContext);

  const [addReview, { loading }] = useMutation(ADD_REVIEW, {
    onCompleted: (d) => {
      setNotification({
        type: "success",
        message: "Successfully added",
      });
      forceCloseDrawer(true);
      refetchReviews();
    },
    onError: (d) => {
      if (d?.message) {
        setNotification({
          type: "error",
          message: d.message,
        });
      } else {
        setNotification({
          type: "error",
          message: "Something went wrong",
        });
      }
    },
  });

  const handleAdd = () => {
    addReview({
      variables: {
        payload: {
          name: payload.name,
          description: payload.description,
          rate: parseInt(payload.rate),
          avatar: payload.avatar,
        },
      },
    });
  };

  return (
    <div className="flex flex-col gap-6">
      {inputs.map((input, idx) => (
        <Input
          key={idx}
          label={input.label}
          placeholder={input.label}
          defaultValue={payload[input.key]}
          onChange={(text) =>
            setPayload((prevState) => ({ ...prevState, [input.key]: text }))
          }
        />
      ))}

      {textareas.map((input, idx) => (
        <Textarea
          key={idx}
          label={input.label}
          placeholder={input.label}
          defaultValue={payload[input.key]}
          onChange={(text) =>
            setPayload((prevState) => ({ ...prevState, [input.key]: text }))
          }
        />
      ))}

      <Button
        title="Add Review"
        className="text-indigo-600 border-indigo-600 hover:text-indigo-500 hover:border-indigo-500"
        onClick={handleAdd}
      />
    </div>
  );
};

const Add = ({ refetchReviews }) => {
  const [forceClose, setForceClose] = useState(false);

  const navigate = useNavigate();

  const closeDrawer = () => {
    navigate("/reviews");
  };

  return (
    <Drawer
      active={true}
      onClose={closeDrawer}
      title="Add Review"
      forceClose={forceClose}
      setForceClose={setForceClose}
      component={
        <ReviewDrawer refetchReviews={refetchReviews} forceCloseDrawer={setForceClose} />
      }
    />
  );
};

export default Add;
