import OverviewStatistics from "pages/overview-with-statistics";
import Users from "pages/users";
import Countries from "pages/countries";
import Rewards from "pages/rewards";
import Withdrawals from "pages/withdrawals";
import Settings from "pages/settings";
import Offerwalls from "pages/offerwalls";
import FeaturedOffers from "pages/featured-offers";
import WithdrawalMethods from "pages/withdrawalMethods";
import Announcements from "pages/announcements";
import FAQ from "pages/faq";
import Reviews from "pages/reviews";
import Uploads from "pages/upload";
import Backup from "pages/backup";
import Chat from "pages/chat";
import ConversionPoints from "pages/conversion-points";
import Tasks from "pages/tasks";

const router = [
	{
		name: "Overview with Statistics",
		path: "/",
		component: () => <OverviewStatistics />,
	},
	{
		name: "Conversion Points",
		path: "/conversion-points/*",
		component: () => <ConversionPoints />,
	},
	{
		name: "Chat",
		path: "/chat",
		component: () => <Chat />,
	},
	{
		name: "Users",
		path: "/users/*",
		component: () => <Users />,
	},
	{
		name: "Backup",
		path: "/backup",
		component: () => <Backup />,
	},
	{
		name: "Countries",
		path: "/countries/*",
		component: () => <Countries />,
	},
	{
		name: "Tasks",
		path: "/tasks/*",
		component: () => <Tasks />,
	},
	{
		name: "Offerwalls",
		path: "/offerwalls/*",
		component: () => <Offerwalls />,
	},
	{
		name: "Featured Offers",
		path: "/featured-offers/*",
		component: () => <FeaturedOffers />,
	},
	{
		name: "Announcements",
		path: "/announcements/*",
		component: () => <Announcements />,
	},
	{
		name: "FAQ",
		path: "/faq/*",
		component: () => <FAQ />,
	},
	{
		name: "Reviews",
		path: "/reviews/*",
		component: () => <Reviews />,
	},
	{
		name: "Uploads",
		path: "/upload/*",
		component: () => <Uploads />,
	},
	{
		name: "Withdrawal Methods",
		path: "/withdrawal-methods/*",
		component: () => <WithdrawalMethods />,
	},
	{
		name: "Rewards",
		path: "/rewards/*",
		component: () => <Rewards />,
	},
	{
		name: "Withdrawals",
		path: "/withdrawals",
		component: () => <Withdrawals />,
	},
	{
		name: "Settings",
		path: "/settings",
		component: () => <Settings />,
	},
];

export default router;
