import { gql } from "@apollo/client";

export const GET_ALL_TASKS = gql`
	query {
		getAllTasks {
			id
			key
			title
			description
			amount
			prize
			priority
		}
	}
`;

export const GET_TASK_BY_ID = gql`
	query GetTaskById($taskId: ID!) {
		getTaskById(taskId: $taskId) {
			id
			key
			title
			description
			amount
			prize
			priority
		}
	}
`;
