import { gql } from "@apollo/client";

export const UPDATE_OFFERWALL = gql`
  mutation UpdateOfferwall($payload: UpdateOfferwallInput, $offerwallId: ID!) {
    updateOfferwall(payload: $payload, offerwallId: $offerwallId)
  }
`;

export const ADD_OFFERWALL = gql`
  mutation AddOfferwall($payload: AddOfferwallInput) {
    addOfferwall(payload: $payload) {
      id
    }
  }
`;

export const DELETE_OFFERWALL = gql`
  mutation DeleteOfferwall($offerwallId: ID!) {
    deleteOfferwall(offerwallId: $offerwallId)
  }
`;
