import { Fragment, useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, CheckIcon } from "@heroicons/react/solid";
import ReactCountryFlag from "react-country-flag";
import { useParams, useLocation } from "react-router-dom";

import { NotificationContext } from "context/notification";

import Table from "common/components/table";

import { GET_ALL_USERS } from "api/query/get-all-users";

const getIcon = (state) => {
  return state ? (
    <CheckIcon className="w-5 h-5 ml-[50%] -translate-x-1/2" color="#22c55e" />
  ) : (
    <XIcon className="w-5 h-5 ml-[50%] -translate-x-1/2" color="#ef4444" />
  );
};

const header = [
  {
    key: "id",
    value: "ID",
    sortable: true,
  },
  {
    key: "name",
    value: "NAME",
    sortable: true,
  },
  {
    key: "email",
    value: "EMAIL",
  },
  {
    key: "phone",
    value: "PHONE",
  },
  {
    key: "username",
    value: "USERNAME",
    sortable: true,
  },
  {
    key: "type",
    value: "ACCOUNT TYPE",
  },
  {
    key: "banned",
    value: "BANNED",
    sortable: true,
  },
  {
    key: "verified",
    value: "VERIFIED",
    sortable: true,
  },
  {
    key: "duplicate",
    value: "DUPLICATE",
    sortable: true,
  },
  {
    key: "invalidIp",
    value: "PROXY/VPN",
    sortable: true,
  },
  {
    key: "isAdmin",
    value: "IS ADMIN",
    sortable: true,
  },
  {
    key: "country",
    value: "COUNTRY",
  },
  {
    key: "lastIp",
    value: "LAST IP",
    sortable: true,
  },
  {
    key: "registrationIp",
    value: "REGISTRATION IP",
    sortable: true,
  },
  {
    key: "upline",
    value: "UPLINE",
  },
  {
    key: "source",
    value: "SOURCE",
  },
];

export default function UserReferrals({ active = true, onClose = () => {} }) {
  const [open, setOpen] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState({});
  const [data, setData] = useState([]);
  const [totalUsers, setTotal] = useState(0);

  const { setNotification } = useContext(NotificationContext);

  const { id } = useParams();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const { data: { getAllUsers: { users, total } = {} } = {}, refetch: refetchUsers } =
    useQuery(GET_ALL_USERS, {
      variables: {
        referralUserId: id,
        page,
        sortBy,
      },
    });

  useEffect(() => {
    if (total !== totalUsers && total) {
      setTotal(total);
    }
  }, [total]);

  useEffect(() => {
    if (users) {
      setData(users);
    }
  }, [users]);

  useEffect(() => {
    if (parseInt(searchParams.get("page"), 10) > 0) {
      setPage(parseInt(searchParams.get("page")));
    }
  }, [searchParams.get("page")]);

  useEffect(() => {
    setOpen(active);
  }, [active]);

  const closeModal = () => {
    setOpen(false);

    setTimeout(() => {
      onClose();
    }, 400);
  };

  const sortTableBy = (name) => {
    // Reset page to 1
    setPage(1);

    setSortBy((prevState) => ({
      name,
      type: prevState.type === "DESC" ? "ASC" : "DESC",
    }));
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-hidden"
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute inset-5 sm:inset-10 md:inset-16 lg:inset-28 bg-white rounded-md shadow overflow-y-auto">
            <div className="relative w-full h-full p-4">
              <button
                type="button"
                className="rounded-md absolute top-2 right-2 bg-white text-gray-400 hover:text-gray-500 focus:outline-none "
                onClick={closeModal}
              >
                <span className="sr-only">Close panel</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              <div className="px-4 py-4 sm:px-6 md:px-0">
                <h1 className="text-3xl font-bold text-gray-700">User Referrals</h1>
              </div>

              <Table
                header={header.map((hdr) => {
                  if (hdr.sortable) {
                    hdr.onClick = sortTableBy;
                  }

                  return hdr;
                })}
                page={page}
                total={totalUsers}
                data={data?.map((user) => ({
                  ...user,
                  invalidIp: getIcon(user.invalidIp),
                  banned: getIcon(user.banned),
                  verified: getIcon(user.verified),
                  isAdmin: getIcon(user.isAdmin),
                  duplicate: getIcon(user.duplicate),
                  country: (
                    <ReactCountryFlag
                      svg
                      className="w-8 h-8 ml-[50%] -translate-x-1/2 shadow"
                      countryCode={user?.country?.code || "XX"}
                      title={user?.country?.name || "XX"}
                    />
                  ),
                }))}
              />
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
