import { useContext } from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { NotificationContext } from "context/notification";

import Button from "common/components/button";

const Card = ({
  hideImage,
  image,
  imageClass = "",
  title,
  link = "",
  onEdit,
  onDelete,
  description,
  copyLink,
  copyToClipboard = "",
}) => {
  const { setNotification } = useContext(NotificationContext);

  return (
    <div className="shadow bg-white  min-h-[200px] w-[210px] max-h-[300px] rounded-md overflow-hidden relative mx-auto xs:mx-0">
      <div className="absolute top-2 right-2 flex gap-2">
        <div
          className="bg-white rounded-full p-2 z-10 shadow cursor-pointer"
          onClick={onEdit}
        >
          <PencilIcon className="w-4 h-4 " color="#6366f1" />
        </div>
        <div
          className="bg-white rounded-full p-2 z-10 shadow cursor-pointer"
          onClick={onDelete}
        >
          <TrashIcon className="w-4 h-4" color="#f95263" />
        </div>
      </div>
      {hideImage ? null : (
        <div
          className={`w-full ${imageClass} min-h-[120px] flex justify-center items-center`}
        >
          <img src={image} alt="" className={`w-10/12 `} />
        </div>
      )}
      <div className="p-2">
        <p className="mb-1">{title}</p>
        {link ? (
          <a href={link} className="break-words text-xs text-gray-400 mb-2">
            {link.slice(0, 70)}
            {link.length > 70 ? "..." : null}
          </a>
        ) : null}
        {copyToClipboard ? (
          <CopyToClipboard
            text={copyToClipboard}
            onCopy={() => {
              setNotification({
                type: "success",
                message: "Copied to clipboard",
              });
            }}
          >
            <div className="break-words text-xs text-gray-400 mb-2 cursor-pointer">
              {copyToClipboard.slice(0, 70)}
              {copyToClipboard.length > 70 ? "..." : null}
            </div>
          </CopyToClipboard>
        ) : null}
        <div className="text-gray-600 text-xs">{description}</div>
      </div>
    </div>
  );
};

export default Card;
