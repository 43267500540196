import { gql } from "@apollo/client";

export const GET_ALL_CONVERSION_POINTS = gql`
	query GetAllConversionPoints {
		getAllConversionPoints {
			id
			name
			value
			link
			active
			hash
			pricePerConversion
		}
	}
`;

export const GET_CONVERSION_STATS = gql`
	query GetConversionStats($hash: String!, $pricePerConversion: Float) {
		conversionStats(hash: $hash, pricePerConversion: $pricePerConversion) {
			userRegistered
			conversions
			userEarned
			totalCost
			profit
		}
	}
`;
