import { useState, useContext, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useLocation, useNavigate, Routes, Route } from "react-router-dom";
import { CheckIcon, XIcon, SearchIcon, RefreshIcon } from "@heroicons/react/outline";

import { NotificationContext } from "context/notification";

import { getStatus } from "utilities";

import AddReward from "pages/rewards/components/add";
import Table from "common/components/table";
import Select from "common/components/select";
import Input from "common/components/input";
import Button from "common/components/button";
import Modal from "common/components/modal";

import { GET_ALL_REWARDS } from "api/query/get-all-rewards";

import { APPROVE_PENDING_REWARDS } from "api/mutations/change-reward-status";
import {
	CHANGE_REWARD_STATUS,
	CHANGE_REWARD_STATUS_BULK,
} from "api/mutations/change-reward-status";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export const header = [
	{
		key: "id",
		value: "ID",
		sortable: true,
	},
	{
		key: "createdAt",
		value: "DATE",
		sortable: true,
	},
	{
		key: "countryCode",
		value: "COUNTRY CODE",
	},
	{
		key: "ip",
		value: "USER IP",
	},
	{
		key: "isProxy",
		value: "IS PROXY",
	},
	{
		key: "name",
		value: "OFFER NAME",
		sortable: true,
	},
	{
		key: "userId",
		value: "USER ID",
	},
	{
		key: "username",
		value: "USERNAME",
		sortable: true,
	},
	{
		key: "amount",
		value: "AMOUNT",
		sortable: true,
	},
	{
		key: "payout",
		value: "PAYOUT",
	},
	{
		key: "provider",
		value: "PROVIDER",
		sortable: true,
	},
	{
		key: "status",
		value: "STATUS",
		sortable: true,
	},
];

export const filterRewardsSelect = [
	{
		id: 1,
		value: "All",
	},
	{
		id: 2,
		value: "Completed",
		status: 1,
	},
	{
		id: 3,
		value: "Pending",
		status: 0,
	},
	{
		id: 4,
		value: "Rejected",
		status: 2,
	},
];

const getIcon = (state) => {
	return state ? (
		<CheckIcon className="w-5 h-5 ml-[50%] -translate-x-1/2" color="#22c55e" />
	) : (
		<XIcon className="w-5 h-5 ml-[50%] -translate-x-1/2" color="#ef4444" />
	);
};

export default function OverviewStatistics({ setSidebarOpen }) {
	const [filters, setFilters] = useState({});
	const [modalActive, setModalActive] = useState(false);
	const [modalPage, setModalPage] = useState(false);
	const [page, setPage] = useState(1);
	const [sortBy, setSortBy] = useState({});
	const [data, setData] = useState([]);
	const [currentPageItems, setCurrentPageItems] = useState([]);
	const [totalRewards, setTotal] = useState(0);

	const { setNotification } = useContext(NotificationContext);
	const location = useLocation();
	const navigate = useNavigate();

	const searchParams = new URLSearchParams(location.search);

	const {
		data: { getAllRewards: { rewards, total } = {} } = {},
		refetch: refetchRewards,
	} = useQuery(GET_ALL_REWARDS, {
		variables: {
			filter: filters,
			page,
			sortBy,
		},
	});

	useEffect(() => {
		if (total !== totalRewards && typeof total === "number") {
			setTotal(total);
		}
	}, [total]);

	useEffect(() => {
		if (rewards) {
			setData(rewards);
		}
	}, [rewards]);

	useEffect(() => {
		if (parseInt(searchParams.get("page"), 10) > 0) {
			setPage(parseInt(searchParams.get("page")));
		}
	}, [searchParams.get("page")]);

	const [changeRewardStatus] = useMutation(CHANGE_REWARD_STATUS, {
		onCompleted: (d) => {
			setNotification({
				type: "success",
				message: "Successfully updated",
			});
			refetchRewards();
		},
		onError: (d) => {
			setNotification({
				type: "error",
				message: "Something went wrong",
			});
		},
	});

	const [changeRewardStatusBulk] = useMutation(CHANGE_REWARD_STATUS_BULK, {
		onCompleted: (d) => {
			setNotification({
				type: "success",
				message: "Successfully updated",
			});
			refetchRewards();
		},
		onError: (d) => {
			setNotification({
				type: "error",
				message: "Something went wrong",
			});
		},
	});

	const [approvePending, { loading }] = useMutation(APPROVE_PENDING_REWARDS, {
		onCompleted: (d) => {
			setNotification({
				type: "success",
				message: "Successfully updated",
			});
			refetchRewards();
		},
		onError: (d) => {
			setNotification({
				type: "error",
				message: "Something went wrong",
			});
		},
	});

	const actions = [
		{
			icon: RefreshIcon,
			onClick: (info) => {
				changeRS(info.id, 0);
			},
			color: "rgb(209 213 219)",
			status_real: 0,
		},
		{
			icon: CheckIcon,
			onClick: (info) => {
				changeRS(info.id, 1);
			},
			color: "#22c55e",
			status_real: 1,
		},
		{
			icon: XIcon,
			onClick: (info) => {
				changeRS(info.id, 2);
			},
			color: "#ef4444",
			status_real: 2,
		},
	];

	const changeRS = (id, status) => {
		changeRewardStatus({
			variables: {
				payload: {
					rewardId: parseInt(id, 10),
					status,
				},
			},
		});
	};

	const setSelectFilter = (info) => {
		setFilters((prevState) => ({
			...prevState,
			status: info.status,
		}));
	};

	const approveAllPending = () => {
		approvePending();
	};

	const approveThisPage = () => {
		const filtered = currentPageItems?.reduce((acc, curr) => {
			if (curr.status_real === 0) {
				acc.push(parseInt(curr.id, 10));
			}

			return acc;
		}, []);

		if (filtered?.length) {
			changeRewardStatusBulk({
				variables: {
					payload: {
						rewardIds: filtered,
						status: 1,
					},
				},
			});
		}
	};

	const sortTableBy = (name) => {
		// Reset page to 1
		setPage(1);

		setSortBy((prevState) => ({
			name,
			type: prevState.type === "DESC" ? "ASC" : "DESC",
		}));
	};

	const searchRewards = (type, data) => {
		setFilters((prevState) => ({
			...prevState,
			[type]: data,
		}));
	};

	return (
		<div className="mx-4">
			<Routes>
				<Route path="/add" element={<AddReward refetchRewards={refetchRewards} />} />
			</Routes>
			<Modal
				active={modalActive}
				setActive={setModalActive}
				title="Are you sure?"
				message="You are about to approve all pending rewards"
				onSubmit={approveAllPending}
			/>
			<Modal
				active={modalPage}
				setActive={setModalPage}
				title="Are you sure?"
				message={`You are about to approve pending rewards from this page with following ids: ${currentPageItems
					?.reduce((acc, curr) => {
						if (curr.status_real === 0) {
							acc.push(curr.id);
						}

						return acc;
					}, [])
					.join(", ")}`}
				onSubmit={approveThisPage}
			/>
			<div className="px-4 py-4 sm:px-6 md:px-0">
				<h1 className="text-3xl font-bold text-gray-700">Rewards</h1>
			</div>
			<div className="my-4 flex justify-between flex-col xl:flex-row gap-4">
				<div className="flex gap-2 flex-wrap lg:min-w-[800px]">
					<Select
						data={filterRewardsSelect}
						className="max-w-[200px]"
						onChange={setSelectFilter}
					/>

					<Input
						placeholder="Search"
						onChange={(data) => searchRewards("search", data)}
						prefix={<SearchIcon className="w-4 h-4" />}
						className="max-w-[200px]"
					/>

					<Input
						placeholder="Min payout"
						type="number"
						className="max-w-[150px]"
						onChange={(data) => searchRewards("minPayout", Number(data || 0))}
					/>

					<Input
						placeholder="Search by provider"
						onChange={(data) => searchRewards("provider", data)}
						prefix={<SearchIcon className="w-4 h-4" />}
						className="max-w-[200px]"
					/>
				</div>
				<div className="flex gap-2 flex-wrap justify-end">
					<Button
						onClick={() => setModalPage(true)}
						className="max-w-[408px] max-h-[50px]"
						title="Approve this page"
					/>
					<Button
						onClick={() => setModalActive(true)}
						className="max-w-[408px] max-h-[50px]"
						title="Approve all pending rewards"
					/>
					<Button
						className="max-w-[200px] max-h-[50px]"
						title="Add new Reward"
						onClick={() => navigate("add")}
					/>
				</div>
			</div>
			<Table
				actions={actions}
				page={page}
				setCurrentPageItems={setCurrentPageItems}
				total={totalRewards}
				currentPageItems={currentPageItems}
				actionsToLeft={true}
				header={header.map((hdr) => {
					if (hdr.sortable) {
						hdr.onClick = sortTableBy;
					}

					return hdr;
				})}
				data={data?.map((reward) => ({
					...reward,
					renderActions: true,
					filterActions: "status_real",
					status_real: parseInt(reward.status == "3" ? "1" : reward.status, 10),
					isProxy: getIcon(reward.isProxy),
					username: (
						<a
							className="text-blue-600"
							href={`/users?username=${encodeURIComponent(reward?.user?.username)}`}
						>
							{reward?.user?.username}
						</a>
					),
					userId: reward?.user?.id,
					status: getStatus(reward.status),
				}))}
			/>
		</div>
	);
}
