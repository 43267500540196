import { gql } from "@apollo/client";

export const APPROVE_PENDING_WITHDRAWALS = gql`
  mutation ApprovePendingWithdrawals($userId: ID) {
    changePendingToCompletedWithdrawals(userId: $userId)
  }
`;

export const REJECT_PENDING_WITHDRAWALS = gql`
  mutation RejectPendingWithdrawals($userId: ID) {
    changePendingToRejectedWithdrawals(userId: $userId)
  }
`;

export const CHANGE_WITHDRAWAL_STATUS = gql`
  mutation ChangeWithdrawalStatus($payload: ChangeWithdrawalStatus!) {
    changeWithdrawalStatus(payload: $payload)
  }
`;
