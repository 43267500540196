import { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";

import { NotificationContext } from "context/notification";

import { GET_TASK_BY_ID } from "api/query/tasks";
import { UPDATE_TASK } from "api/mutations/tasks";

import Drawer from "common/components/drawer";
import Input from "common/components/input";
import Button from "common/components/button";
import Select from "common/components/select";

const inputs = [
	{ label: "Title", key: "title" },
	{ label: "Description", key: "description" },
	{ label: "Amount to reach", key: "amount" },
	{ label: "Prize in points", key: "prize" },
	{ label: "Priority", key: "priority" },
];

function TaskDrawer({ taskId, forceCloseDrawer = () => {}, refetchTasks }) {
	const [payload, setPayload] = useState({});
	const [key, setKey] = useState("referFriend");

	const { setNotification } = useContext(NotificationContext);

	const navigate = useNavigate();

	// Fetch tasks data
	const { data: { getTaskById: task } = {} } = useQuery(GET_TASK_BY_ID, {
		variables: {
			taskId,
		},
	});

	const [updateTask, { loading }] = useMutation(UPDATE_TASK, {
		onCompleted: (d) => {
			setNotification({
				type: "success",
				message: "Successfully updated",
			});
			forceCloseDrawer(true);
			refetchTasks();
		},
		onError: (d) => {
			setNotification({
				type: "error",
				message: "Something went wrong",
			});
		},
	});

	useEffect(() => {
		if (task) {
			setPayload({
				title: task.title,
				description: task.description,
				amount: parseInt(task.amount),
				prize: parseInt(task.prize),
				priority: parseInt(task.priority),
			});
			setKey(task.key);
		}
	}, [task]);

	const handleUpdate = () => {
		updateTask({
			variables: {
				taskId,
				payload: {
					...payload,
					amount: parseInt(payload.amount),
					prize: parseInt(payload.prize),
					priority: parseInt(payload.priority),
					key,
				},
			},
		});
	};

	return (
		<div className="flex flex-col gap-6">
			{inputs.map((input, idx) => (
				<Input
					key={idx}
					label={input.label}
					placeholder={input.label}
					defaultValue={payload[input.key]}
					onChange={(text) =>
						setPayload((prevState) => ({ ...prevState, [input.key]: text }))
					}
				/>
			))}

			<Select
				label="Select key"
				defaultValue={{ referFriend: 0, earnPoints: 1, reachLevel: 2 }[key]}
				onChange={(key) => setKey(key.id)}
				data={[
					{ id: "referFriend", value: "Refer Friend" },
					{ id: "earnPoints", value: "Earn Points" },
					{ id: "reachLevel", value: "Reach Level" },
				]}
			/>

			<Button
				title="Save changes"
				className="text-indigo-600 border-indigo-600 hover:text-indigo-500 hover:border-indigo-500"
				onClick={handleUpdate}
			/>
		</div>
	);
}

export default function EditTask({ refetchTasks }) {
	const [forceClose, setForceClose] = useState(false);

	const params = useParams();
	const navigate = useNavigate();

	const closeDrawer = () => {
		navigate("/tasks");
	};

	return (
		<Drawer
			active={true}
			onClose={closeDrawer}
			title="Edit Task"
			forceClose={forceClose}
			setForceClose={setForceClose}
			component={
				<TaskDrawer
					refetchTasks={refetchTasks}
					forceCloseDrawer={setForceClose}
					taskId={params.id}
				/>
			}
		/>
	);
}
