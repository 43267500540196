import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Stats({
  name,
  total,
  change,
  changeType,
  StatIcon,
  showChange = false,
  absolute,
}) {
  return (
    <div>
      <dl className="mt-5 min-h-[90px] grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <div className="col-span-1 sm:col-span-2 lg:col-span-3 relative bg-white pt-5 sm:pt-6 px-6 shadow rounded-lg overflow-hidden">
          <div className="absolute top-2 right-2 text-gray-300 text-sm">{absolute}</div>
          <dt>
            <div className="absolute bg-indigo-500 rounded-md p-3">
              <StatIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <p className="ml-16 text-sm font-medium text-gray-500 truncate">{name}</p>
          </dt>
          <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
            <p className="text-2xl font-semibold text-gray-900">{total}</p>
            <p
              className={classNames(
                changeType === "increase" ? "text-green-600" : "text-red-600",
                "ml-2 flex items-baseline text-sm font-semibold"
              )}
            >
              {!showChange ? (
                ""
              ) : changeType === "increase" ? (
                <ArrowSmUpIcon
                  className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                  aria-hidden="true"
                />
              ) : (
                <ArrowSmDownIcon
                  className="self-center flex-shrink-0 h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              )}

              <span className="sr-only">
                {changeType === "increase" ? "Increased" : "Decreased"} by
              </span>
              {change}
            </p>
          </dd>
        </div>
      </dl>
    </div>
  );
}
