import { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { NotificationContext } from "context/notification";

import { ADD_CONVERSION_POINTS } from "api/mutations/conversion-points";

import Drawer from "common/components/drawer";
import Input from "common/components/input";
import Switch from "common/components/switch";
import Textarea from "common/components/textarea";
import Button from "common/components/button";

const inputs = [
	{
		label: "Name *",
		key: "name",
	},
	{
		label: "Link *",
		key: "link",
	},
	{
		label: "Value *",
		key: "value",
		type: "number",
	},
	{
		label: "Price per conversion (default: 0)",
		key: "pricePerConversion",
		type: "number",
	},
];

const ConversionPointsDrawer = ({ forceCloseDrawer, refetchConversionPoints }) => {
	const [payload, setPayload] = useState({});
	const [active, setActive] = useState(false);

	const { setNotification } = useContext(NotificationContext);

	const [addConversionPoints, { loading }] = useMutation(ADD_CONVERSION_POINTS, {
		onCompleted: (d) => {
			setNotification({
				type: "success",
				message: "Successfully added",
			});
			forceCloseDrawer(true);
			refetchConversionPoints();
		},
		onError: (d) => {
			if (d?.message) {
				setNotification({
					type: "error",
					message: d.message,
				});
			} else {
				setNotification({
					type: "error",
					message: "Something went wrong",
				});
			}
		},
	});

	const handleAdd = () => {
		addConversionPoints({
			variables: {
				payload: {
					name: payload.name,
					link: payload.link,
					value: Number(payload.value),
          pricePerConversion: Number(payload.pricePerConversion),
					active,
				},
			},
		});
	};

	return (
		<div className="flex flex-col gap-6">
			{inputs.map((input, idx) => (
				<Input
					key={idx}
					type={input.type}
					label={input.label}
					placeholder={input.label}
					defaultValue={payload[input.key]}
					onChange={(text) =>
						setPayload((prevState) => ({ ...prevState, [input.key]: text }))
					}
				/>
			))}

			<Switch enabled={active} setEnabled={setActive} label="Active" />

			<Button
				title="Add Conversion Points"
				className="text-indigo-600 border-indigo-600 hover:text-indigo-500 hover:border-indigo-500"
				onClick={handleAdd}
			/>
		</div>
	);
};

const Add = ({ refetchConversionPoints }) => {
	const [forceClose, setForceClose] = useState(false);

	const navigate = useNavigate();

	const closeDrawer = () => {
		navigate("/conversion-points");
	};

	return (
		<Drawer
			active={true}
			onClose={closeDrawer}
			title="Add Conversion Points"
			forceClose={forceClose}
			setForceClose={setForceClose}
			component={
				<ConversionPointsDrawer
					refetchConversionPoints={refetchConversionPoints}
					forceCloseDrawer={setForceClose}
				/>
			}
		/>
	);
};

export default Add;
