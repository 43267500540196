import { useState, useContext } from "react";
import nookies from "nookies";
import { useLazyQuery } from "@apollo/client";

import { NotificationContext } from "context/notification";
import { MATIX_TOKEN } from "common/constants";

import { LOGIN_USER } from "api/query/login";

import Input from "common/components/input";
import Notification from "common/components/notification";

import styles from "./login.module.css";

export default function Login() {
	const [payload, setPayload] = useState({});

	const { setNotification } = useContext(NotificationContext);

	const [loginMe, { loading }] = useLazyQuery(LOGIN_USER, {
		onCompleted: (d) => {
			if (d?.adminLogin?.token) {
				nookies.set(null, MATIX_TOKEN, d?.adminLogin?.token, { maxAge: 10800 });
				window.location.href = "/";
			} else {
				setNotification({
					message: "Something went wrong",
					type: "error",
				});
			}
		},
		onError: (d) => {
			setNotification({
				message: d.message || "Something went wrong",
				type: "error",
			});
		},
	});

	const loginUser = (e) => {
		e.preventDefault();

		if (payload.email && payload.password) {
			loginMe({
				variables: {
					payload,
				},
			});
		}
	};

	return (
		<>
			<div
				className="min-h-full flex flex-col  py-12 sm:px-6 lg:px-8"
				style={{ backgroundColor: "#1d2333", minHeight: "100vh" }}
			>
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<div className={styles.logo}>
						<img src="/icons/logo-vikinger.svg" />
						<p>MATIX</p>
					</div>
					<h2 className="mt-6 text-center text-3xl font-extrabold text-white">
						Sign in to your account
					</h2>
				</div>

				<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<div
						className="py-8 px-4 shadow sm:rounded-lg sm:px-10"
						style={{ backgroundColor: "#293249" }}
					>
						<form className="space-y-6" onSubmit={loginUser}>
							<div>
								<Input
									labelStyle={{ color: "#fff" }}
									inputStyle={{
										color: "#fff",
										backgroundColor: "#1d2333",
										border: "1px solid #1d2333",
									}}
									label="Email address"
									placeholder="Email"
									type="email"
									onChange={(email) => {
										setPayload((prevState) => ({ ...prevState, email }));
									}}
								/>
							</div>

							<div>
								<Input
									labelStyle={{ color: "#fff" }}
									inputStyle={{
										color: "#fff",
										backgroundColor: "#1d2333",
										border: "1px solid #1d2333",
									}}
									label="Password"
									placeholder="Password"
									type="password"
									onChange={(password) => {
										setPayload((prevState) => ({ ...prevState, password }));
									}}
								/>
							</div>

							<div>
								<button
									onClick={loginUser}
									type="submit"
									className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
								>
									Sign in
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>

			<Notification />
		</>
	);
}
