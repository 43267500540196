import { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useLocation, Routes, Route, useNavigate } from "react-router-dom";

import { CheckIcon, XIcon, PencilIcon, TrashIcon } from "@heroicons/react/outline";

import EditTask from "./components/edit";
import CreateTask from "./components/add";

import Button from "common/components/button";
import Table from "common/components/table";
import Modal from "common/components/modal";

import { NotificationContext } from "context/notification";

import { GET_ALL_TASKS } from "api/query/tasks";
import { DELETE_TASK } from "api/mutations/tasks";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const header = [
	{
		key: "id",
		value: "ID",
	},
	{
		key: "title",
		value: "Title",
	},
	{
		key: "description",
		value: "Description",
	},
	{
		key: "amount",
		value: "Amount to reach",
	},
	{
		key: "prize",
		value: "Prize in points",
	},
	{
		key: "priority",
		value: "Priority (highest is first)",
	},
];

const getIcon = (state) => {
	return state ? (
		<CheckIcon className="w-5 h-5" color="#22c55e" />
	) : (
		<XIcon className="w-5 h-5" color="#ef4444" />
	);
};

export default function Tasks({ setSidebarOpen }) {
	const [modalActive, setModalActive] = useState(false);
	const [deleteTaskId, setDeleteTask] = useState();

	const navigate = useNavigate();

	const { setNotification } = useContext(NotificationContext);

	const {
		client,
		data: { getAllTasks: tasks } = {},
		refetch: refetchTasks,
		loading,
	} = useQuery(GET_ALL_TASKS);

	const [deleteTaskMutation] = useMutation(DELETE_TASK, {
		onCompleted: (d) => {
			if (d?.destroyTask) {
				setNotification({
					type: "success",
					message: "Successfully deleted",
				});
				refetchTasks();
			} else {
				setNotification({
					type: "error",
					message: "Something went wrong",
				});
			}
		},
		onError: (d) => {
			setNotification({
				type: "error",
				message: "Something went wrong",
			});
		},
	});

	const actions = [
		{
			icon: PencilIcon,
			onClick: (task) => {
				navigate(`edit/${task.id}`);
			},
			color: "#6366f1",
		},
		{
			icon: TrashIcon,
			onClick: (task = {}) => {
				setDeleteTask(task.id);
				setModalActive(true);
			},
			color: "#ef4444",
		},
	];

	return (
		<div className="mx-4">
			<Modal
				active={modalActive}
				setActive={setModalActive}
				title="Are you sure?"
				message={`You are going to delete task with id ${deleteTaskId}`}
				onSubmit={() =>
					deleteTaskMutation({
						variables: {
							id: deleteTaskId,
						},
					})
				}
			/>
			<Routes>
				<Route path="/edit/:id" element={<EditTask refetchTasks={refetchTasks} />} />
				<Route path="/add" element={<CreateTask refetchTasks={refetchTasks} />} />
			</Routes>
			<div className="px-4 py-4 sm:px-6 md:px-0">
				<h1 className="text-3xl font-bold text-gray-700">Tasks</h1>
			</div>
			<div className="my-4 flex justify-end flex-col lg:flex-row gap-4">
				<Button
					onClick={() => navigate("add")}
					className="max-w-[408px]"
					title="Add new task"
				/>
			</div>
			<Table
				header={header}
				actions={actions}
				loading={loading && !tasks?.length}
				allowRerender={true}
				data={tasks?.map((task) => ({
					...task,
					renderActions: true,
					name: "",
				}))}
				frontPagination={true}
				perPage={10}
			/>
		</div>
	);
}
