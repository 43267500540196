import { gql } from "@apollo/client";

export const GET_ALL_REVIEWS = gql`
  query GetAllReviews {
    getAllReviews {
      id
      name
      description
      rate
      avatar
    }
  }
`;
