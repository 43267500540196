import { gql } from "@apollo/client";

export const UPDATE_REVIEW = gql`
  mutation UpdateReview($payload: EditReviewInput!, $reviewID: ID!) {
    updateReview(payload: $payload, reviewID: $reviewID) {
      id
    }
  }
`;

export const ADD_REVIEW = gql`
  mutation AddReview($payload: CreateReviewInput!) {
    addReview(payload: $payload) {
      id
    }
  }
`;

export const DELETE_REVIEW = gql`
  mutation DeleteReview($reviewID: ID!) {
    deleteReview(reviewID: $reviewID)
  }
`;
