import { useState, useContext, createContext } from "react";
import { Navigate } from "react-router-dom";

import { useQuery } from "@apollo/client";

import { GET_ME } from "api/query/get-me";

const UserContext = createContext({});

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});

  const { refetch, data } = useQuery(GET_ME, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (d) => {
      setUser(d?.me);
    },
  });

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        refetchUser: refetch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
