import { useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate, Routes, Route, Link } from "react-router-dom";
import { CheckIcon, XIcon, PlusCircleIcon } from "@heroicons/react/outline";

import { PencilIcon } from "@heroicons/react/outline";

import { NotificationContext } from "context/notification";

import EditFeaturedOffer from "./components/edit";
import AddFeaturedOffer from "./components/add";

import Drawer from "common/components/drawer";
import Card from "common/components/card";
import Modal from "common/components/modal";
import Button from "common/components/button";

import { GET_ALL_FEATURED_OFFERS } from "api/query/get-all-featured-offers";
import { DELETE_FEATURED_OFFER } from "api/mutations/featured-offer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const getIcon = (state) => {
  return state ? (
    <CheckIcon className="w-5 h-5 ml-[50%] -translate-x-1/2" color="#22c55e" />
  ) : (
    <XIcon className="w-5 h-5 ml-[50%] -translate-x-1/2" color="#ef4444" />
  );
};

export default function FeaturedOffers({ setSidebarOpen }) {
  const [modalActive, setModalActive] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { setNotification } = useContext(NotificationContext);
  const navigate = useNavigate();

  const {
    data: { getFeaturedOffers: featuredOffers } = {},
    refetch: refetchFeaturedOffers,
  } = useQuery(GET_ALL_FEATURED_OFFERS, {});

  const [deleteFeaturedOfferMutation, { loading }] = useMutation(DELETE_FEATURED_OFFER, {
    onCompleted: (d) => {
      if (d?.deleteFeaturedOffer) {
        setNotification({
          type: "success",
          message: "Successfully deleted",
        });
        refetchFeaturedOffers();
      } else {
        setNotification({
          type: "error",
          message: "Something went wrong",
        });
      }
    },
    onError: (d) => {
      setNotification({
        type: "error",
        message: "Something went wrong",
      });
    },
  });

  const onEdit = (featuredOffer) => {
    navigate(`edit/${featuredOffer.id}`);
  };

  const deleteFeaturedOffer = (id) => {
    if (id !== null) {
      deleteFeaturedOfferMutation({
        variables: {
          featuredOfferId: id,
        },
      });
    }
  };

  const onDelete = (featuredOffer) => {
    setDeleteId(featuredOffer.id);
    setModalActive(true);
  };

  const onModalClose = () => {
    setDeleteId(null);
  };

  const addFeaturedOfferDrawer = () => {
    navigate("add");
  };

  const generateDescription = (featuredOffer = {}) => {
    return (
      <div className="text-xs text-gray-500">
        <p className="mt-2 break-words">
          Description: <span className="text-gray-400">{featuredOffer.description}</span>
        </p>
      </div>
    );
  };

  return (
    <div className="mx-4">
      <Routes>
        <Route
          path="/edit/:id"
          element={<EditFeaturedOffer refetchFeaturedOffers={refetchFeaturedOffers} />}
        />
        <Route
          path="/add"
          element={<AddFeaturedOffer refetchFeaturedOffers={refetchFeaturedOffers} />}
        />
      </Routes>
      <Modal
        active={modalActive}
        setActive={setModalActive}
        title="Are you sure?"
        message="You are about to delete an Featured Offer"
        onSubmit={() => deleteFeaturedOffer(deleteId)}
      />
      <div className="px-4 py-4 sm:px-6 md:px-0">
        <h1 className="text-3xl font-bold text-gray-700">Featured Offers</h1>
      </div>
      <div className="my-4 flex flex-wrap gap-4">
        {featuredOffers?.map((featuredOffer, idx) => (
          <Card
            key={idx}
            image={featuredOffer.image}
            title={featuredOffer.name}
            link={featuredOffer.link}
            description={generateDescription(featuredOffer)}
            onEdit={() => onEdit(featuredOffer)}
            onDelete={() => onDelete(featuredOffer)}
          />
        ))}

        <div
          className="shadow bg-white min-h-[200px] w-[210px] max-h-[300px] rounded-md overflow-hidden relative mx-auto xs:mx-0 cursor-pointer flex justify-center items-center flex-col gap-1"
          onClick={addFeaturedOfferDrawer}
        >
          <PlusCircleIcon className="w-8 h-8 text-gray-400" />
          <span className="text-xs text-gray-400">Add new</span>
        </div>
      </div>
    </div>
  );
}
