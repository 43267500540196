import { Fragment, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dialog, Transition, Menu } from "@headlessui/react";
import {
	ChartBarIcon,
	GlobeAltIcon,
	GiftIcon,
	CurrencyDollarIcon,
	CogIcon,
	LogoutIcon,
	UsersIcon,
	XIcon,
	BriefcaseIcon,
	CreditCardIcon,
	UploadIcon,
	DownloadIcon,
	SpeakerphoneIcon,
	StarIcon,
	ChatIcon,
	CursorClickIcon,
	DocumentTextIcon,
} from "@heroicons/react/outline";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { destroyCookie } from "nookies";

import { UserContext } from "context/user";

import { MATIX_TOKEN } from "common/constants";

import styles from "./style.module.css";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const navigation = [
	{ name: "Overview", href: "/", icon: ChartBarIcon },
	{ name: "Chat", href: "/chat", icon: ChatIcon },
	{
		name: "Users",
		href: "/users",
		icon: UsersIcon,
	},
	{
		name: "Offerwalls",
		href: "/offerwalls",
		icon: BriefcaseIcon,
	},
	{
		name: "Featured Offers",
		href: "/featured-offers",
		icon: BriefcaseIcon,
	},
	{
		name: "Withdrawal Methods",
		href: "/withdrawal-methods",
		icon: CreditCardIcon,
	},
	{
		name: "Countries",
		href: "/countries",
		icon: GlobeAltIcon,
	},
	{
		name: "Rewards",
		href: "/rewards",
		icon: GiftIcon,
	},
	{
		name: "Withdrawals",
		href: "/withdrawals",
		icon: CurrencyDollarIcon,
	},
	{
		name: "Announcements",
		href: "/announcements",
		icon: SpeakerphoneIcon,
	},
	{
		name: "Tasks",
		href: "/tasks",
		icon: DocumentTextIcon,
	},
	{
		name: "FAQ",
		href: "/faq",
		icon: QuestionMarkCircleIcon,
	},
	{
		name: "Reviews",
		href: "/reviews",
		icon: StarIcon,
	},
	{
		name: "Conversion Points",
		href: "/conversion-points",
		icon: CursorClickIcon,
	},
	{
		name: "Upload",
		href: "/upload",
		icon: UploadIcon,
	},
	{
		name: "Backup",
		href: "/backup",
		icon: DownloadIcon,
	},
	{
		name: "Settings",
		href: "/settings",
		icon: CogIcon,
	},
];

const UserDropdown = () => {
	const { user } = useContext(UserContext);

	const logout = () => {
		destroyCookie({}, MATIX_TOKEN, {
			path: "/",
		});

		window.location.href = "/login";
	};

	return (
		<Menu as="div" className="relative inline-block text-left">
			<Menu.Button className="flex items-center cursor-pointer">
				<div>
					<img
						className="inline-block h-9 w-9 rounded-full bg-blue-400 flex items-center justify-center text-white"
						src=""
						alt={user?.id ? user?.name?.[0]?.toUpperCase() : "A"}
					/>
				</div>
				<div className="ml-3">
					<p className="text-sm font-medium text-white">
						{user?.id ? user?.name : "Admin"}
					</p>
				</div>
			</Menu.Button>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="absolute left-0 top-0 -translate-y-[130%] w-full min-w-[200px] mt-2 origin-top-right bg-white divide-y divide-gray-100 shadow-lg focus:outline-none shadow">
					<Menu.Item>
						<button
							className={
								"text-gray-400 group flex items-center w-full px-2 py-2 text-sm flex gap-2 justify-between items-center"
							}
							onClick={logout}
						>
							Logout
							<LogoutIcon className="w-6 h-6" />
						</button>
					</Menu.Item>
				</Menu.Items>
			</Transition>
		</Menu>
	);
};

export default function Sidebar({ sidebarOpen = false, setSidebarOpen = () => {} }) {
	const { user } = useContext(UserContext);

	const location = useLocation();
	const active = location.pathname;

	const toggleSidebar = () => {
		setSidebarOpen((prevState) => !prevState);
	};

	if (!user?.isAdmin && !user?.isEditor) {
		return <div></div>;
	}

	const editorRoutes = ["/chat"];

	return (
		<>
			<Transition.Root show={sidebarOpen} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 flex z-40 md:hidden"
					onClose={toggleSidebar}
				>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
					</Transition.Child>
					<Transition.Child
						as={Fragment}
						enter="transition ease-in-out duration-300 transform"
						enterFrom="-translate-x-full"
						enterTo="translate-x-0"
						leave="transition ease-in-out duration-300 transform"
						leaveFrom="translate-x-0"
						leaveTo="-translate-x-full"
					>
						<div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
							<Transition.Child
								as={Fragment}
								enter="ease-in-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in-out duration-300"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<div className="absolute top-0 right-0 -mr-12 pt-2">
									<button
										type="button"
										className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
										onClick={toggleSidebar}
									>
										<span className="sr-only">Close sidebar</span>
										<XIcon className="h-6 w-6 text-white" aria-hidden="true" />
									</button>
								</div>
							</Transition.Child>
							<div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
								<div className="flex-shrink-0 flex items-center px-4">
									<div className={styles.logo}>
										<img src="/icons/logo-vikinger.svg" />
										<p>MATIX</p>
									</div>
								</div>
								<nav className="mt-5 px-2 space-y-1">
									{navigation
										.filter(
											(item) => user?.isAdmin || editorRoutes.indexOf(item.href) > -1
										)
										.map((item) => (
											<Link
												key={item.name}
												to={item.href}
												className={classNames(
													(item.href !== "/" && active.includes(item.href)) ||
														item.href === active
														? "bg-gray-900 text-white"
														: "text-gray-300 hover:bg-gray-700 hover:text-white",
													"group flex items-center px-2 py-2 text-base font-medium rounded-md"
												)}
												onClick={toggleSidebar}
											>
												<item.icon
													className={classNames(
														item.current
															? "text-gray-300"
															: "text-gray-400 group-hover:text-gray-300",
														"mr-4 flex-shrink-0 h-6 w-6"
													)}
													aria-hidden="true"
												/>
												{item.name}
											</Link>
										))}
								</nav>
							</div>
							<div className="flex-shrink-0 flex bg-gray-700 p-4">
								<UserDropdown />
							</div>
						</div>
					</Transition.Child>
					<div className="flex-shrink-0 w-14">
						{/* Force sidebar to shrink to fit close icon */}
					</div>
				</Dialog>
			</Transition.Root>

			{/* Static sidebar for desktop */}
			<div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
				{/* Sidebar component, swap this element with another sidebar if you like */}
				<div className="flex-1 flex flex-col min-h-0 bg-gray-800">
					<div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
						<div className="flex items-center flex-shrink-0 px-4">
							<div className={styles.logo}>
								<img src="/icons/logo-vikinger.svg" />
								<p>MATIX</p>
							</div>
						</div>
						<nav className="mt-5 flex-1 px-2 space-y-1">
							{navigation
								.filter((item) => user?.isAdmin || editorRoutes.indexOf(item.href) > -1)
								.map((item) => (
									<Link
										key={item.name}
										to={item.href}
										className={classNames(
											(item.href !== "/" && active.includes(item.href)) ||
												item.href === active
												? "bg-gray-900 text-white"
												: "text-gray-300 hover:bg-gray-700 hover:text-white",
											"group flex items-center px-2 py-2 text-sm font-medium rounded-md"
										)}
									>
										<item.icon
											className={classNames(
												item.current
													? "text-gray-300"
													: "text-gray-400 group-hover:text-gray-300",
												"mr-3 flex-shrink-0 h-6 w-6"
											)}
											aria-hidden="true"
										/>
										{item.name}
									</Link>
								))}
						</nav>
					</div>
					<div className="flex-shrink-0 flex bg-gray-700 p-4">
						<UserDropdown />
					</div>
				</div>
			</div>
		</>
	);
}
