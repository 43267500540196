export const getStatus = (code) => {
  if (code == 0) {
    return (
      <span className="px-4 py-2 text-yellow-400 border text-xs border-yellow-400 rounded-md">
        PENDING
      </span>
    );
  }

  if (code == 1) {
    return (
      <span className="px-4 py-2 text-green-400 border text-xs border-green-400 rounded-md">
        COMPLETED
      </span>
    );
  }

  if (code == 2) {
    return (
      <span className="px-4 py-2 text-red-400 border text-xs border-red-400 rounded-md">
        REJECTED
      </span>
    );
  }

  if (code == 3) {
    return (
      <span className="px-4 py-2 text-red-400 border text-xs border-red-400 rounded-md">
        CHARGEBACK
      </span>
    );
  }
};

export const getChartDataset = (label, data, color) => {
  return {
    label: label,
    data: data,
    fill: true,
    lineTension: 0.1,
    backgroundColor: "rgba(95, 225, 224, 0.1)",
    borderColor: color,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBackgroundColor: color,
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
  };
};

export const getChartOptions = (title) => {
  return {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          padding: 24,
          usePointStyle: true,
        },
      },
      title: {
        display: true,
        text: title,
        padding: {
          top: 10,
          bottom: 30,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#94A8CF",
        },
      },
      y: {
        grid: {
          color: "#dfe9fe",
        },
        ticks: {
          color: "#94A8CF",
          maxTicksLimit: 9,
        },
      },
    },
  };
};
