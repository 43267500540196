import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
  query Login($payload: UserLoginInput!) {
    adminLogin(payload: $payload) {
      token
      user {
        id
        isAdmin
      }
    }
  }
`;
