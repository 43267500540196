import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import { NotificationProvider } from "context/notification";
import { UserProvider } from "context/user";
import Login from "pages/login";
import DashboardLayout from "./layout";

import { withAuth } from "common/hoc";

import client from "api";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const App = () => {
  const C = withAuth(DashboardLayout);
  return (
    <ApolloProvider client={client}>
      <UserProvider>
        <NotificationProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/login" exact element={<Login />} />
              <Route path="*" element={<C />} />
            </Routes>
          </BrowserRouter>
        </NotificationProvider>
      </UserProvider>
    </ApolloProvider>
  );
};

export default App;
