import { gql } from "@apollo/client";

export const PRIVATE_MESSAGE_CREATED = gql`
  subscription PrivateMessageCreated($userId: ID) {
    privateMessageCreated(userId: $userId) {
      id
      name
      avatar
      level
      type
      createdAt
      message
    }
  }
`;
