import { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";

import { NotificationContext } from "context/notification";

import { GET_COUNTRY_BY_ID } from "api/query/get-country-by-id";
import { UPDATE_COUNTRY } from "api/mutations/update-country";

import Drawer from "common/components/drawer";
import Input from "common/components/input";
import Button from "common/components/button";
import Switch from "common/components/switch";
import Select from "common/components/select";

const inputs = [
  { label: "Name", key: "name" },
  { label: "Min Value Fraud", key: "minValueFraudCheck" },
];

function CountryDrawer({ countryId, forceCloseDrawer = () => {}, refetchCountries }) {
  const [blocked, blockCountry] = useState(false);
  const [fraudCheck, setFraudCheck] = useState(false);
  const [payload, setPayload] = useState({});

  const { setNotification } = useContext(NotificationContext);

  const navigate = useNavigate();

  // Fetch country data
  const { data: { getCountryById: country } = {} } = useQuery(GET_COUNTRY_BY_ID, {
    variables: {
      countryId,
    },
  });

  const [updateCountry, { loading }] = useMutation(UPDATE_COUNTRY, {
    onCompleted: (d) => {
      setNotification({
        type: "success",
        message: "Successfully updated",
      });
      forceCloseDrawer(true);
      refetchCountries();
    },
    onError: (d) => {
      setNotification({
        type: "error",
        message: "Something went wrong",
      });
    },
  });

  useEffect(() => {
    if (country) {
      setPayload({
        name: country.name,
        minValueFraudCheck: country.minValueFraudCheck,
      });
      blockCountry(country.blocked);
      setFraudCheck(country.fraudCheck);
    }
  }, [country]);

  const handleUpdate = () => {
    updateCountry({
      variables: {
        countryId,
        payload: {
          ...payload,
          minValueFraudCheck: parseInt(payload.minValueFraudCheck, 10),
          blocked,
          fraudCheck,
        },
      },
    });
  };

  return (
    <div className="flex flex-col gap-6">
      {inputs.map((input, idx) => (
        <Input
          key={idx}
          label={input.label}
          placeholder={input.label}
          defaultValue={payload[input.key]}
          onChange={(text) =>
            setPayload((prevState) => ({ ...prevState, [input.key]: text }))
          }
        />
      ))}

      <Switch enabled={fraudCheck} setEnabled={setFraudCheck} label="Fraud Check" />
      <Switch enabled={blocked} setEnabled={blockCountry} label="Blocked" />

      <Button
        title="Save changes"
        className="text-indigo-600 border-indigo-600 hover:text-indigo-500 hover:border-indigo-500"
        onClick={handleUpdate}
      />
    </div>
  );
}

export default function EditCountry({ refetchCountries }) {
  const [forceClose, setForceClose] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const closeDrawer = () => {
    navigate("/countries");
  };

  return (
    <Drawer
      active={true}
      onClose={closeDrawer}
      title="Edit Country"
      forceClose={forceClose}
      setForceClose={setForceClose}
      component={
        <CountryDrawer
          refetchCountries={refetchCountries}
          forceCloseDrawer={setForceClose}
          countryId={params.id}
        />
      }
    />
  );
}
