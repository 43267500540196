import { Fragment, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";

import { GET_CONVERSION_STATS } from "api/query/get-all-conversion-points";

import Spinner from "common/components/spinner";

const renderData = [
	{ key: "userRegistered", label: "User Registered" },
	{ key: "conversions", label: "Conversions" },
	{ key: "userEarned", label: "Users Earned", prefix: "$" },
	{ key: "profit", label: "Profit", prefix: "$" },
	{ key: "totalCost", label: "Total Cost", prefix: "$" },
];

export default function Stats({
	active = true,
	onClose = () => {},
	hash = null,
	pricePerConversion = 0,
}) {
	const [open, setOpen] = useState(false);

	const { data: { conversionStats } = {}, loading } = useQuery(GET_CONVERSION_STATS, {
		variables: {
			hash,
			pricePerConversion,
		},
	});

	useEffect(() => {
		setOpen(active);
	}, [active]);

	const closeModal = () => {
		setOpen(false);

		setTimeout(() => {
			onClose();
		}, 400);
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-10 overflow-hidden"
				onClose={closeModal}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 bg-white rounded-md shadow overflow-y-auto w-[300px] min-h-[200px]">
						<div className="relative w-full h-full p-4">
							<button
								type="button"
								className="rounded-md absolute top-2 right-2 bg-white text-gray-400 hover:text-gray-500 focus:outline-none "
								onClick={closeModal}
							>
								<span className="sr-only">Close panel</span>
								<XIcon className="h-6 w-6" aria-hidden="true" />
							</button>

							<div className="mt-4 flex flex-col">
								{loading ? (
									<div className="flex justify-center items-center min-h-[280px]">
										<Spinner />
									</div>
								) : (
									renderData?.map((item, idx) => (
										<div
											className="py-4 border border-x-0 border-t-0 border-gray-300 flex w-full"
											key={idx}
										>
											<p className="w-3/4">{item?.label}</p>
											<p className="w-1/4">
												{item?.prefix}
												{conversionStats?.[item.key] || 0}
											</p>
										</div>
									))
								)}
							</div>
						</div>
					</div>
				</Transition.Child>
			</Dialog>
		</Transition.Root>
	);
}
