import { useState, useEffect, useContext, memo } from "react";
import { useQuery, useMutation } from "@apollo/client";
import ReactCountryFlag from "react-country-flag";
import { useNavigate, Routes, Route, Link, useLocation } from "react-router-dom";
import { SearchIcon, TrashIcon } from "@heroicons/react/outline";

import AddUpload from "pages/upload/components/add";
import Table from "common/components/table";
import Input from "common/components/input";
import Select from "common/components/select";
import Button from "common/components/button";
import Modal from "common/components/modal";

import { NotificationContext } from "context/notification";

import { GET_ALL_UPLOADS } from "api/query/get-all-uploads";
import { DELETE_UPLOAD } from "api/mutations/upload";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const header = [
  {
    key: "id",
    value: "ID",
  },
  {
    key: "name",
    value: "NAME",
  },
  {
    key: "link",
    value: "LINK",
  },
];

const Uploads = ({ setSidebarOpen }) => {
  const [filters, setFilters] = useState({
    search: "",
  });
  const [modalActive, setModalActive] = useState(false);
  const [deleteUploadId, setDeleteUpload] = useState();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalUploads, setTotal] = useState(0);

  const { setNotification } = useContext(NotificationContext);

  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const {
    data: { getAllUploads: { uploads, total } = {} } = {},
    refetch: refetchUploads,
  } = useQuery(GET_ALL_UPLOADS, {
    variables: {
      filter: filters,
      page,
    },
  });

  const [deleteUploadMutation] = useMutation(DELETE_UPLOAD, {
    onCompleted: (d) => {
      if (d?.deleteUpload) {
        setNotification({
          type: "success",
          message: "Successfully deleted",
        });
        refetchUploads();
      } else {
        setNotification({
          type: "error",
          message: "Something went wrong",
        });
      }
    },
    onError: (d) => {
      setNotification({
        type: "error",
        message: "Something went wrong",
      });
    },
  });

  useEffect(() => {
    if (total !== totalUploads && total) {
      setTotal(total);
    }
  }, [total]);

  useEffect(() => {
    if (uploads) {
      setData(uploads);
    }
  }, [uploads]);

  useEffect(() => {
    if (parseInt(searchParams.get("page"), 10) > 0) {
      setPage(parseInt(searchParams.get("page")));
    }
  }, [searchParams.get("page")]);

  const actions = [
    {
      icon: TrashIcon,
      onClick: (upload = {}) => {
        setDeleteUpload(upload.id);
        setModalActive(true);
      },
      color: "#ef4444",
    },
  ];

  const searchUploads = (data) => {
    setFilters((prevState) => ({
      ...prevState,
      search: data,
    }));
  };

  return (
    <div className="mx-4">
      <Modal
        active={modalActive}
        setActive={setModalActive}
        title="Are you sure?"
        message={`You are going to delete upload with id ${deleteUploadId}`}
        onSubmit={() =>
          deleteUploadMutation({
            variables: {
              id: deleteUploadId,
            },
          })
        }
      />
      <Routes>
        <Route path="/add" element={<AddUpload refetchUploads={refetchUploads} />} />
      </Routes>
      <div className="px-4 py-4 sm:px-6 md:px-0">
        <h1 className="text-3xl font-bold text-gray-700">Uploads</h1>
      </div>
      <div className="my-4 flex justify-between flex-col lg:flex-row gap-4">
        <Input
          placeholder="Search"
          onChange={searchUploads}
          prefix={<SearchIcon className="w-4 h-4" />}
          className="max-w-[200px]"
        />

        <Button
          onClick={() => navigate("add")}
          className="max-w-[408px]"
          title="Upload new document"
        />
      </div>
      <div className="my-4" />
      <Table
        page={page}
        total={totalUploads}
        header={header}
        data={data?.map((upload) => ({
          ...upload,
          renderActions: true,
        }))}
        actions={actions}
      />
    </div>
  );
};

export default memo(Uploads);
