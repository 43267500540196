import { gql } from "@apollo/client";

export const UPDATE_QUESTION = gql`
  mutation EditQuestion($payload: EditQuestion!, $id: ID!) {
    editQuestion(payload: $payload, id: $id)
  }
`;

export const ADD_QUESTION = gql`
  mutation AddNewQuestion($payload: CreateQuestion!) {
    addNewQuestion(payload: $payload) {
      id
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation DeleteQuestion($id: ID!) {
    deleteQuestion(id: $id)
  }
`;
