import { useState, useEffect } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Textarea({
  label = "",
  type = "text",
  onChange = () => {},
  placeholder = "",
  defaultValue = "",
  className = "",
  rows = 3,
}) {
  const [text, setText] = useState("");

  const onInputChange = (e) => {
    setText(e.target.value);
  };

  useEffect(() => {
    onChange(text || "");
  }, [text]);

  useEffect(() => {
    if (defaultValue) {
      setText(defaultValue || "");
    }
  }, [defaultValue]);

  return (
    <div className={classNames(className)}>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <textarea
          className={`focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-4 sm:text-sm border-gray-300 rounded-md pl-4`}
          rows={rows}
          value={text}
          onChange={onInputChange}
          placeholder={placeholder}
        ></textarea>
      </div>
    </div>
  );
}
