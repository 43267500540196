const Button = ({ title, onClick = () => {}, className = "", startIcon = null }) => {
	return (
		<button
			type="button"
			className={`${className} rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none border border-purple-600 hover:border-purple-500 px-4 py-2 ${className}`}
			onClick={onClick}
		>
			{startIcon}
			{title}
		</button>
	);
};

export default Button;
