import { gql } from "@apollo/client";

export const APPROVE_PENDING_REWARDS = gql`
	mutation ApprovePendingRewards($userId: ID) {
		changePendingToCompletedReward(userId: $userId)
	}
`;

export const CHANGE_REWARD_STATUS = gql`
	mutation ChangeRewardStatus($payload: ChangeRewardStatusInput!) {
		changeRewardStatus(payload: $payload)
	}
`;

export const CHANGE_REWARD_STATUS_BULK = gql`
	mutation ChangeRewardStatus($payload: ChangeStatusBulk!) {
		changeRewardStatusBulk(payload: $payload)
	}
`;
