import { useState, useEffect, useContext, memo } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import ReactCountryFlag from "react-country-flag";
import { useNavigate, Routes, Route, Link, useLocation } from "react-router-dom";
import {
	CheckIcon,
	XIcon,
	PencilIcon,
	SearchIcon,
	TrashIcon,
} from "@heroicons/react/outline";

import EditUser from "pages/users/components/edit";
import UserRewards from "pages/users/components/rewards";
import UserWithdrawals from "pages/users/components/withdrawals";
import UserReferrals from "pages/users/components/referrals";
import Table from "common/components/table";
import Input from "common/components/input";
import Select from "common/components/select";
import Button from "common/components/button";
import Modal from "common/components/modal";

import { NotificationContext } from "context/notification";

import { GET_ALL_USERS } from "api/query/get-all-users";
import { CALCULATE_USER_BALANCE } from "api/query/user-balance";
import { DELETE_USER } from "api/mutations/update-user";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const header = [
	{
		key: "id",
		value: "ID",
		sortable: true,
	},
	{
		key: "email",
		value: "EMAIL",
	},
	{
		key: "username",
		value: "USERNAME",
		sortable: true,
	},
	{
		key: "country",
		value: "COUNTRY",
	},
	{
		key: "banned",
		value: "BANNED",
		sortable: true,
	},
	{
		key: "invalidIp",
		value: "PROXY/VPN",
		sortable: true,
	},
	{
		key: "duplicate",
		value: "DUPLICATE",
		sortable: true,
	},
	{
		key: "visitorId",
		value: "VISITOR ID",
	},
	{
		key: "calculateBalance",
		value: "CALCULATE BALANCE",
	},
	{
		key: "rewards",
		value: "REWARDS",
	},
	{
		key: "withdrawals",
		value: "WITHDRAWALS",
	},
	{
		key: "referrals",
		value: "REFERRALS",
	},
	{
		key: "emailVerified",
		value: "EMAIL VERIFIED",
	},
	{
		key: "verified",
		value: "VERIFIED",
		sortable: true,
	},
	{
		key: "isEditor",
		value: "IS EDITOR",
		sortable: true,
	},
	{
		key: "isAdmin",
		value: "IS ADMIN",
		sortable: true,
	},
	{
		key: "promoter",
		value: "PROMOTER",
		sortable: true,
	},
	{
		key: "ofid",
		value: "AFFILIATE CLICK ID",
	},
	{
		key: "gid",
		value: "GOAL ID",
	},
	{
		key: "conversionFired",
		value: "CONVERSION FIRED",
	},
	{
		key: "name",
		value: "NAME",
		sortable: true,
	},
	{
		key: "phone",
		value: "PHONE",
	},
	{
		key: "type",
		value: "ACCOUNT TYPE",
	},
	{
		key: "lastIp",
		value: "LAST IP",
		sortable: true,
	},
	{
		key: "registrationIp",
		value: "REGISTRATION IP",
		sortable: true,
	},
	{
		key: "upline",
		value: "UPLINE",
	},
	{
		key: "source",
		value: "SOURCE",
	},
];

const filterUsersSelect = [
	{
		id: 1,
		value: "All",
	},
	{
		id: 2,
		value: "Duplicate accounts",
		key: "duplicate",
	},
	{
		id: 3,
		value: "Banned accounts",
		key: "banned",
	},
];

const getIcon = (state) => {
	return state ? (
		<CheckIcon className="w-5 h-5 ml-[50%] -translate-x-1/2" color="#22c55e" />
	) : (
		<XIcon className="w-5 h-5 ml-[50%] -translate-x-1/2" color="#ef4444" />
	);
};

const Users = ({ setSidebarOpen }) => {
	const [filters, setFilters] = useState({});
	const [modalActive, setModalActive] = useState(false);
	const [deleteUserId, setDeleteUser] = useState();
	const [page, setPage] = useState(1);
	const [sortBy, setSortBy] = useState({});
	const [data, setData] = useState([]);
	const [totalUsers, setTotal] = useState(0);
	const [balances, setBalances] = useState({});

	const { setNotification } = useContext(NotificationContext);

	const location = useLocation();
	const navigate = useNavigate();

	const searchParams = new URLSearchParams(location.search);

	const { data: { getAllUsers: { users, total } = {} } = {}, refetch: refetchUsers } =
		useQuery(GET_ALL_USERS, {
			variables: {
				filter: filters,
				page,
				sortBy,
			},
		});

	const [getUserBalance] = useLazyQuery(CALCULATE_USER_BALANCE);

	const [deleteUserMutation] = useMutation(DELETE_USER, {
		onCompleted: (d) => {
			if (d?.deleteUser) {
				setNotification({
					type: "success",
					message: "Successfully deleted",
				});
				refetchUsers();
			} else {
				setNotification({
					type: "error",
					message: "Something went wrong",
				});
			}
		},
		onError: (d) => {
			setNotification({
				type: "error",
				message: "Something went wrong",
			});
		},
	});

	useEffect(() => {
		if (total !== totalUsers && total) {
			setTotal(total);
		}
	}, [total]);

	useEffect(() => {
		if (users) {
			setData(users);
		}
	}, [users]);

	useEffect(() => {
		if (parseInt(searchParams.get("upage"), 10) > 0) {
			setPage(parseInt(searchParams.get("upage")));
		}
	}, [searchParams.get("upage")]);

	useEffect(() => {
		if (searchParams.get("s")) {
			setFilters((prev) => ({
				...prev,
				search: searchParams.get("s"),
			}));
		}
	}, [searchParams.get("s")]);

	useEffect(() => {
		if (searchParams.get("username")) {
			setFilters((prev) => ({
				...prev,
				username: searchParams.get("username"),
			}));
		}
	}, [searchParams.get("username")]);

	const actions = [
		{
			icon: PencilIcon,
			onClick: (user) => {
				navigate(`edit/${user.id}`);
			},
			color: "#6366f1",
		},
		{
			icon: TrashIcon,
			onClick: (user = {}) => {
				setDeleteUser(user.id);
				setModalActive(true);
			},
			color: "#ef4444",
		},
	];

	const setFilterSelect = (info) => {
		setFilters((prevState) => ({
			...prevState,
			type: info.key,
		}));
	};

	const searchUsers = (data) => {
		setFilters((prevState) => ({
			...prevState,
			search: data,
		}));
	};

	const listModal = (user, str) => {
		navigate(str + "/" + user.id);
	};

	const closeModal = () => {
		navigate("/users");
	};

	const sortTableBy = (name) => {
		// Reset page to 1
		setPage(1);

		setSortBy((prevState) => ({
			name,
			type: prevState.type === "DESC" ? "ASC" : "DESC",
		}));
	};

	const calculateUserBalance = async (userId, username) => {
		try {
			const response = await getUserBalance({
				variables: {
					userId,
				},
			});

			if (
				response &&
				response.data &&
				typeof response.data.calculateUserBalance === "number"
			) {
				setBalances((prevState) => ({
					...prevState,
					[username]: response.data.calculateUserBalance,
				}));
			}
		} catch (e) {}
	};

	return (
		<div className="mx-4">
			<Modal
				active={modalActive}
				setActive={setModalActive}
				title="Are you sure?"
				message={`You are going to delete user with id ${deleteUserId}`}
				onSubmit={() =>
					deleteUserMutation({
						variables: {
							id: deleteUserId,
						},
					})
				}
			/>
			<Routes>
				<Route path="/edit/:id" element={<EditUser refetchUsers={refetchUsers} />} />
				<Route path="/reward/:id" element={<UserRewards onClose={closeModal} />} />
				<Route
					path="/withdrawal/:id"
					element={<UserWithdrawals onClose={closeModal} />}
				/>
				<Route path="/referral/:id" element={<UserReferrals onClose={closeModal} />} />
			</Routes>
			<div className="px-4 py-4 sm:px-6 md:px-0">
				<h1 className="text-3xl font-bold text-gray-700">Users</h1>
			</div>
			<div className="flex gap-2 flex-wrap">
				<Select
					label=""
					data={filterUsersSelect}
					className="max-w-[200px]"
					onChange={setFilterSelect}
				/>
				<Input
					placeholder="Search"
					onChange={searchUsers}
					prefix={<SearchIcon className="w-4 h-4" />}
					className="max-w-[200px]"
				/>
			</div>
			<div className="my-4" />
			<Table
				page={page}
				pageParam="upage"
				actionsToLeft={true}
				total={totalUsers}
				header={header.map((hdr) => {
					if (hdr.sortable) {
						hdr.onClick = sortTableBy;
					}

					return hdr;
				})}
				data={data?.map((user) => ({
					...user,
					invalidIp: getIcon(user.invalidIp),
					banned: getIcon(user.banned),
					verified: getIcon(user.verified),
					renderActions: true,
					isEditor: getIcon(user.isEditor),
					conversionFired: getIcon(user.conversionFired),
					duplicate: getIcon(user.duplicate),
					isAdmin: getIcon(user.isAdmin),
					emailVerified: getIcon(user.emailVerified),
					promoter: getIcon(user.promoter),
					rewards: (
						<Button
							title="List rewards"
							onClick={() => listModal(user, "reward")}
							className="text-xs"
						/>
					),
					withdrawals: (
						<Button
							title="List withdrawals"
							onClick={() => listModal(user, "withdrawal")}
							className="text-xs"
						/>
					),
					referrals: (
						<Button
							title="List referrals"
							onClick={() => listModal(user, "referral")}
							className="text-xs"
						/>
					),
					calculateBalance: Number.isNaN(Number(balances[user?.username])) ? (
						<Button
							title="Calculate balance"
							className="text-xs"
							onClick={() => calculateUserBalance(user?.id, user?.username)}
						/>
					) : (
						<div className="text-center">{balances[user?.username]}</div>
					),
					country: (
						<ReactCountryFlag
							svg
							className="w-8 h-8 ml-[50%] -translate-x-1/2 shadow"
							countryCode={user?.country?.code || "XX"}
							title={user?.country?.name || "XX"}
						/>
					),
				}))}
				actions={actions}
			/>
		</div>
	);
};

export default memo(Users);
