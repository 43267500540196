import { Fragment, useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, CheckIcon, RefreshIcon } from "@heroicons/react/solid";
import { useParams, useLocation } from "react-router-dom";

import { NotificationContext } from "context/notification";

import { getStatus } from "utilities";

import { header, filterRewardsSelect } from "pages/rewards";

import Table from "common/components/table";
import Select from "common/components/select";
import Button from "common/components/button";
import Modal from "common/components/modal";

import { GET_ALL_REWARDS } from "api/query/get-all-rewards";

import { APPROVE_PENDING_REWARDS } from "api/mutations/change-reward-status";
import { CHANGE_REWARD_STATUS } from "api/mutations/change-reward-status";

const getIcon = (state) => {
	return state ? (
		<CheckIcon className="w-5 h-5 ml-[50%] -translate-x-1/2" color="#22c55e" />
	) : (
		<XIcon className="w-5 h-5 ml-[50%] -translate-x-1/2" color="#ef4444" />
	);
};

let userCountries = [];

export default function UserRewards({ active = true, onClose = () => {} }) {
	const [filters, setFilters] = useState({});
	const [open, setOpen] = useState(false);
	const [modalActive, setModalActive] = useState(false);
	const [page, setPage] = useState(1);
	const [sortBy, setSortBy] = useState({});
	const [data, setData] = useState([]);
	const [totalRewards, setTotal] = useState(0);

	const { setNotification } = useContext(NotificationContext);

	const { id } = useParams();
	const location = useLocation();

	const searchParams = new URLSearchParams(location.search);

	const {
		data: { getAllRewards: { rewards, total } = {} } = {},
		refetch: refetchRewards,
	} = useQuery(GET_ALL_REWARDS, {
		variables: {
			filter: filters,
			userId: id,
			page,
			sortBy,
		},
	});

	useEffect(() => {
		if (total !== totalRewards && total) {
			setTotal(total);
		}
	}, [total]);

	useEffect(() => {
		if (rewards) {
			userCountries = rewards?.reduce((acc, curr) => {
				if (!acc?.includes(curr?.countryCode)) {
					acc.push(curr?.countryCode);
				}

				return acc;
			}, []);

			setData(rewards);
		}
	}, [rewards]);

	useEffect(() => {
		if (parseInt(searchParams.get("page"), 10) > 0) {
			setPage(parseInt(searchParams.get("page")));
		}
	}, [searchParams.get("page")]);

	const [changeRewardStatus] = useMutation(CHANGE_REWARD_STATUS, {
		onCompleted: (d) => {
			setNotification({
				type: "success",
				message: "Successfully updated",
			});
			refetchRewards();
		},
		onError: (d) => {
			setNotification({
				type: "error",
				message: "Something went wrong",
			});
		},
	});

	const [approvePending, { loading }] = useMutation(APPROVE_PENDING_REWARDS, {
		onCompleted: (d) => {
			setNotification({
				type: "success",
				message: "Successfully updated",
			});
			refetchRewards();
		},
		onError: (d) => {
			setNotification({
				type: "error",
				message: "Something went wrong",
			});
		},
	});

	useEffect(() => {
		setOpen(active);
	}, [active]);

	const actions = [
		{
			icon: RefreshIcon,
			onClick: (info) => {
				changeRS(info.id, 0);
			},
			color: "rgb(209 213 219)",
			status_real: 0,
		},
		{
			icon: CheckIcon,
			onClick: (info) => {
				changeRS(info.id, 1);
			},
			color: "#22c55e",
			status_real: 1,
		},
		{
			icon: XIcon,
			onClick: (info) => {
				changeRS(info.id, 2);
			},
			color: "#ef4444",
			status_real: 2,
		},
	];

	const changeRS = (id, status) => {
		changeRewardStatus({
			variables: {
				payload: {
					rewardId: parseInt(id, 10),
					status,
				},
			},
		});
	};

	const setSelectFilter = (info) => {
		setFilters((prevState) => ({
			...prevState,
			status: info.status,
		}));
	};

	const closeModal = () => {
		setOpen(false);

		setTimeout(() => {
			onClose();
		}, 400);
	};

	const approveUserPending = () => {
		approvePending({
			variables: {
				userId: id,
			},
		});
	};

	const sortTableBy = (name) => {
		// Reset page to 1
		setPage(1);

		setSortBy((prevState) => ({
			name,
			type: prevState.type === "DESC" ? "ASC" : "DESC",
		}));
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-10 overflow-hidden"
				onClose={closeModal}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="absolute inset-5 sm:inset-10 md:inset-16 lg:inset-28 bg-white rounded-md shadow overflow-y-auto">
						<div className="relative w-full h-full p-4">
							<button
								type="button"
								className="rounded-md absolute top-2 right-2 bg-white text-gray-400 hover:text-gray-500 focus:outline-none "
								onClick={closeModal}
							>
								<span className="sr-only">Close panel</span>
								<XIcon className="h-6 w-6" aria-hidden="true" />
							</button>

							<Modal
								active={modalActive}
								setActive={setModalActive}
								title="Are you sure?"
								message="You are about to approve user pending rewards"
								onSubmit={approveUserPending}
							/>

							<div className="px-4 py-4 sm:px-6 md:px-0 flex flex-wrap gap-4">
								<h1 className="text-3xl font-bold text-gray-700">User Rewards</h1>
								{userCountries?.length ? (
									<div className="border px-4 py-2">{userCountries.join(", ")}</div>
								) : null}
							</div>
							<div className="my-4 flex justify-between flex-col sm:flex-row gap-4">
								<Select
									data={filterRewardsSelect}
									className="max-w-[200px]"
									onChange={setSelectFilter}
								/>
								<Button
									onClick={() => setModalActive(true)}
									title="Approve user pending rewards"
								/>
							</div>

							<Table
								actions={actions}
								header={header.map((hdr) => {
									if (hdr.sortable) {
										hdr.onClick = sortTableBy;
									}

									return hdr;
								})}
								page={page}
								total={totalRewards}
								data={data?.map((reward) => ({
									...reward,
									renderActions: true,
									filterActions: "status_real",
									status_real: parseInt(reward.status == "3" ? "1" : reward.status, 10),
									isProxy: getIcon(reward.isProxy),
									username: reward?.user?.username,
									status: getStatus(reward.status),
								}))}
							/>
						</div>
					</div>
				</Transition.Child>
			</Dialog>
		</Transition.Root>
	);
}
