import { useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate, Routes, Route, Link } from "react-router-dom";
import { CheckIcon, XIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { PencilIcon } from "@heroicons/react/outline";

import { NotificationContext } from "context/notification";

import EditConversionPoints from "./components/edit";
import AddConversionPoints from "./components/add";

import Drawer from "common/components/drawer";
import Card from "common/components/card";
import Modal from "common/components/modal";
import Button from "common/components/button";

import StatsModal from "./components/stats";

import { GET_ALL_CONVERSION_POINTS } from "api/query/get-all-conversion-points";
import { DELETE_CONVERSION_POINTS } from "api/mutations/conversion-points";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const getIcon = (state) => {
	return state ? (
		<CheckIcon className="w-5 h-5 ml-[50%] -translate-x-1/2" color="#22c55e" />
	) : (
		<XIcon className="w-5 h-5 ml-[50%] -translate-x-1/2" color="#ef4444" />
	);
};

export default function ConversionPoints({ setSidebarOpen }) {
	const [modalActive, setModalActive] = useState(false);
	const [statsModal, setStatsModal] = useState({ active: false });
	const [deleteId, setDeleteId] = useState(null);

	const { setNotification } = useContext(NotificationContext);
	const navigate = useNavigate();

	const {
		data: { getAllConversionPoints: conversionPoints } = {},
		refetch: refetchConversionPoints,
	} = useQuery(GET_ALL_CONVERSION_POINTS, {});

	const [deleteConversionPointsMutation, { loading }] = useMutation(
		DELETE_CONVERSION_POINTS,
		{
			onCompleted: (d) => {
				setNotification({
					type: "success",
					message: "Successfully deleted",
				});
				refetchConversionPoints();
			},
			onError: (d) => {
				setNotification({
					type: "error",
					message: "Something went wrong",
				});
			},
		}
	);

	const onEdit = (conversionPoint) => {
		navigate(`edit/${conversionPoint.id}`);
	};

	const deleteConversionPoints = (id) => {
		if (id !== null) {
			deleteConversionPointsMutation({
				variables: {
					id: id,
				},
			});
		}
	};

	const onDelete = (conversionPoint) => {
		setDeleteId(conversionPoint.id);
		setModalActive(true);
	};

	const onModalClose = () => {
		setDeleteId(null);
	};

	const addConversionPointsDrawer = () => {
		navigate("add");
	};

	const generateDesc = (conversionPoint) => {
		return (
			<div>
				<p></p>
			</div>
		);
	};

	const generateDescription = (conversionPoint = {}) => {
		return (
			<div className="text-xs text-gray-500 mt-10">
				<div
					className={
						"absolute top-2 left-2 text-white py-1 px-2 rounded-md " +
						(conversionPoint.active ? "bg-green-500" : "bg-red-500")
					}
				>
					{conversionPoint.active ? "ACTIVE" : "INACTIVE"}
				</div>
				<p className="break-words">
					Name: <span className="text-gray-400">{conversionPoint.name}</span>
				</p>
				<p className="mt-2 break-words">
					Amount: <span className="text-gray-400">{conversionPoint.value}</span>
				</p>
				<p className="mt-2 break-words">
					Hash:{" "}
					<CopyToClipboard
						text={conversionPoint.hash}
						onCopy={() => {
							setNotification({
								type: "success",
								message: "Copied to clipboard",
							});
						}}
					>
						<span className="text-gray-400 cursor-pointer">{conversionPoint.hash}</span>
					</CopyToClipboard>
				</p>
				<div className="flex"></div>
				<div className="mt-5 mb-2 float-right">
					<img
						src="icons/stats.svg"
						alt=""
						className="cursor-pointer"
						onClick={() =>
							setStatsModal((prevState) => ({
								hash: conversionPoint.hash,
								pricePerConversion: conversionPoint.pricePerConversion,
								active: !prevState?.active,
							}))
						}
					/>
				</div>
			</div>
		);
	};

	return (
		<div className="mx-4">
			<StatsModal
				active={statsModal?.active}
				setActive={(active) => setStatsModal((prevState) => ({ ...prevState, active }))}
				onClose={() => setStatsModal({ active: false })}
				pricePerConversion={statsModal?.pricePerConversion}
				hash={statsModal?.hash}
			/>
			<Routes>
				<Route
					path="/edit/:id"
					element={
						<EditConversionPoints refetchConversionPoints={refetchConversionPoints} />
					}
				/>
				<Route
					path="/add"
					element={
						<AddConversionPoints refetchConversionPoints={refetchConversionPoints} />
					}
				/>
			</Routes>
			<Modal
				active={modalActive}
				setActive={setModalActive}
				title="Are you sure?"
				message="You are about to delete an Conversion Points"
				onSubmit={() => deleteConversionPoints(deleteId)}
			/>
			<div className="px-4 py-4 sm:px-6 md:px-0">
				<h1 className="text-3xl font-bold text-gray-700">Conversion Points</h1>
			</div>
			<div className="my-4 flex flex-wrap gap-4">
				{conversionPoints?.map((conversionPoint, idx) => (
					<Card
						key={idx}
						hideImage={true}
						description={generateDescription(conversionPoint)}
						onEdit={() => onEdit(conversionPoint)}
						onDelete={() => onDelete(conversionPoint)}
					/>
				))}

				<div
					className="shadow bg-white min-h-[200px] w-[210px] max-h-[300px] rounded-md overflow-hidden relative mx-auto xs:mx-0 cursor-pointer flex justify-center items-center flex-col gap-1"
					onClick={addConversionPointsDrawer}
				>
					<PlusCircleIcon className="w-8 h-8 text-gray-400" />
					<span className="text-xs text-gray-400">Add new</span>
				</div>
			</div>
		</div>
	);
}
