import { gql } from "@apollo/client";

export const GET_ALL_QUESTIONS = gql`
  query GetAllQuestions {
    getAllQuestions {
      id
      question
      answer
      createdAt
    }
  }
`;
