import { Fragment, useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, CheckIcon } from "@heroicons/react/solid";
import { useParams, useLocation } from "react-router-dom";

import { NotificationContext } from "context/notification";

import { getStatus } from "utilities";

import { header, filterWithdrawalsSelect } from "pages/withdrawals";

import Table from "common/components/table";
import Select from "common/components/select";
import Button from "common/components/button";
import Modal from "common/components/modal";
import Spinner from "common/components/spinner";

import { GET_ALL_WITHDRAWALS } from "api/query/get-all-withdrawals";

import {
	APPROVE_PENDING_WITHDRAWALS,
	REJECT_PENDING_WITHDRAWALS,
} from "api/mutations/change-withdrawal-status";
import { CHANGE_WITHDRAWAL_STATUS } from "api/mutations/change-withdrawal-status";

export default function UserWithdrawals({ active = true, onClose = () => {} }) {
	const [filters, setFilters] = useState({});
	const [open, setOpen] = useState(false);
	const [modalActive, setModalActive] = useState(false);
	const [page, setPage] = useState(1);
	const [modalInfo, setModalInfo] = useState({});
	const [sortBy, setSortBy] = useState({});
	const [data, setData] = useState([]);
	const [totalWithdrawals, setTotal] = useState(0);
	const [loadingId, setLoadingId] = useState(null);

	const { setNotification } = useContext(NotificationContext);

	const { id } = useParams();

	const location = useLocation();

	const searchParams = new URLSearchParams(location.search);

	const {
		data: { getAllWithdrawals: { withdrawals, total } = {} } = {},
		refetch: refetchWithdrawals,
	} = useQuery(GET_ALL_WITHDRAWALS, {
		variables: {
			filter: filters,
			userId: id,
			page,
			sortBy,
		},
	});

	useEffect(() => {
		if (total !== totalWithdrawals && total) {
			setTotal(total);
		}
	}, [total]);

	useEffect(() => {
		if (withdrawals) {
			setData(withdrawals);
		}
	}, [withdrawals]);

	useEffect(() => {
		if (parseInt(searchParams.get("page"), 10) > 0) {
			setPage(parseInt(searchParams.get("page")));
		}
	}, [searchParams.get("page")]);

	const [changeWithdrawalStatus, { loading: withdrawalLoading }] = useMutation(
		CHANGE_WITHDRAWAL_STATUS,
		{
			onCompleted: (d) => {
				setNotification({
					type: "success",
					message: "Successfully updated",
				});
				refetchWithdrawals();
			},
			onError: (d) => {
				setNotification({
					type: "error",
					message: d?.message || d?.errors?.[0]?.message || "Something went wrong",
				});
			},
		}
	);

	const [approvePending, { loading: loadingApprove }] = useMutation(
		APPROVE_PENDING_WITHDRAWALS,
		{
			onCompleted: (d) => {
				setNotification({
					type: "success",
					message: "Successfully updated",
				});
				refetchWithdrawals();
			},
			onError: (d) => {
				console.log(d);
				setNotification({
					type: "error",
					message: "Something went wrong",
				});
			},
		}
	);

	const [rejectPending] = useMutation(REJECT_PENDING_WITHDRAWALS, {
		onCompleted: (d) => {
			setNotification({
				type: "success",
				message: "Successfully updated",
			});
			refetchWithdrawals();
		},
		onError: (d) => {
			setNotification({
				type: "error",
				message: "Something went wrong",
			});
		},
	});

	useEffect(() => {
		setOpen(active);
	}, [active]);

	const actions = [
		{
			icon: CheckIcon,
			onClick: (info) => {
				if (withdrawalLoading) {
					setNotification({ type: "info", message: "Already loading" });
					return 0;
				}

				setLoadingId(info.id);
				changeRS(info.id, 1);
			},
			color: "#22c55e",
		},
		{
			icon: XIcon,
			onClick: (info) => {
				if (withdrawalLoading) {
					setNotification({ type: "info", message: "Already loading" });
					return 0;
				}

				setLoadingId(info.id);
				changeRS(info.id, 2);
			},
			color: "#ef4444",
		},
	];

	const changeRS = (id, status) => {
		changeWithdrawalStatus({
			variables: {
				payload: {
					withdrawalId: parseInt(id, 10),
					status,
				},
			},
		});
	};

	const setSelectFilter = (info) => {
		setFilters((prevState) => ({
			...prevState,
			status: info.status,
		}));
	};

	const closeModal = () => {
		setOpen(false);

		setTimeout(() => {
			onClose();
		}, 400);
	};

	const approveUserPending = () => {
		approvePending({
			variables: {
				userId: id,
			},
		});
	};

	const rejectUserPending = () => {
		rejectPending({
			variables: {
				userId: id,
			},
		});
	};

	const sortTableBy = (name) => {
		// Reset page to 1
		setPage(1);

		setSortBy((prevState) => ({
			name,
			type: prevState.type === "DESC" ? "ASC" : "DESC",
		}));
	};

	const changeModal = (message, cb) => {
		setModalInfo({
			message,
			cb,
		});

		setModalActive(true);
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-10 overflow-hidden"
				onClose={closeModal}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="absolute inset-5 sm:inset-10 md:inset-16 lg:inset-28 bg-white rounded-md shadow overflow-y-auto">
						<div className="relative w-full h-full p-4">
							<button
								type="button"
								className="rounded-md absolute top-2 right-2 bg-white text-gray-400 hover:text-gray-500 focus:outline-none "
								onClick={closeModal}
							>
								<span className="sr-only">Close panel</span>
								<XIcon className="h-6 w-6" aria-hidden="true" />
							</button>

							<Modal
								active={modalActive}
								setActive={setModalActive}
								title="Are you sure?"
								message={modalInfo.message}
								onSubmit={modalInfo.cb}
							/>

							<div className="px-4 py-4 sm:px-6 md:px-0">
								<h1 className="text-3xl font-bold text-gray-700">User Withdrawals</h1>
							</div>
							<div className="my-4 flex justify-between flex-col sm:flex-row gap-4">
								<Select
									data={filterWithdrawalsSelect}
									className="max-w-[200px]"
									onChange={setSelectFilter}
								/>
								<div className="flex flex-wrap gap-4 justify-end">
									<Button
										onClick={() => {
											changeModal(
												"You are about to reject user pending withdrawals",
												rejectUserPending
											);
										}}
										className="max-w-[408px] text-red-600 hover:text-red-500 border-red-600 hover:border-red-500"
										title="Reject user pending withdrawals"
									/>
									<Button
										onClick={() => {
											changeModal(
												"You are about to approve user pending withdrawals",
												approveUserPending
											);
										}}
										className=" flex justify-center items-center"
										startIcon={loadingApprove ? <Spinner className="w-4 h-4" /> : null}
										title="Approve user pending withdrawals"
									/>
								</div>
							</div>

							<Table
								actions={actions}
								actionsLoading={withdrawalLoading}
								actionsLoadingId={loadingId}
								header={header.map((hdr) => {
									if (hdr.sortable) {
										hdr.onClick = sortTableBy;
									}

									return hdr;
								})}
								page={page}
								total={totalWithdrawals}
								data={data?.map((withdrawal) => ({
									...withdrawal,
									renderActions: withdrawal.status == 0,
									username: withdrawal?.user?.username,
									status: getStatus(withdrawal.status),
								}))}
							/>
						</div>
					</div>
				</Transition.Child>
			</Dialog>
		</Transition.Root>
	);
}
