import { gql } from "@apollo/client";

export const UPDATE_CONVERSION_POINTS = gql`
	mutation EditConversionPoints($payload: EditConversionPoints!, $id: ID!) {
		editConversionPoints(payload: $payload, id: $id)
	}
`;

export const ADD_CONVERSION_POINTS = gql`
	mutation AddNewConversionPoints($payload: CreateConversionPoints!) {
		addNewConversionPoints(payload: $payload) {
			id
		}
	}
`;

export const DELETE_CONVERSION_POINTS = gql`
	mutation DeleteConversionPoints($id: ID!) {
		deleteConversionPoints(id: $id)
	}
`;
