import { ApolloClient, createHttpLink, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Cookie from "nookies";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";

import { MATIX_TOKEN, HOST_BACKEND, HOST_BACKEND_WS } from "common/constants";

const httpLink = createHttpLink({
  uri: `${HOST_BACKEND}/graphql`,
  fetchPolity: "network-only",
});

const token = Cookie.get(MATIX_TOKEN)[MATIX_TOKEN];

const authLink = setContext((_, { headers }) => {
  //	const token = Cookie.get(MATIX_TOKEN)[MATIX_TOKEN];

  const isAuth = token ? { Authorization: `Bearer ${token}` } : {};

  return {
    headers: {
      ...headers,
      ...isAuth,
    },
  };
});

const wsLink =
  typeof window !== "undefined"
    ? new GraphQLWsLink(
        createClient({
          url: `${HOST_BACKEND_WS}/graphql`,
          connectionParams: {
            authToken: token ? { authorization: `Bearer ${token}` } : null,
          },
        })
      )
    : null;

const splitLink =
  typeof window !== "undefined" && wsLink != null
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        wsLink,
        authLink.concat(httpLink)
      )
    : authLink.concat(httpLink);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
  },
});

export default client;
