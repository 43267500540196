export default function Dropzone({ onChange = () => {}, selectedFile }) {
  return (
    <div className="flex justify-center items-center w-full">
      <label
        for="dropzone-file"
        className="flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-100 "
      >
        <div className="flex flex-col justify-center items-center pt-5 pb-6">
          <img src="/icons/upload.svg" alt="" className="mb-3 w-10 h-10" />
          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
            {selectedFile ? (
              <span className="font-semibold">File selected</span>
            ) : (
              <>
                <span className="font-semibold">Click to upload</span> or drag and drop
              </>
            )}
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-400">
            {/*	SVG, PNG, JPG or GIF (MAX. 800x400px) */}
          </p>
        </div>
        <input id="dropzone-file" type="file" className="hidden" onChange={onChange} />
      </label>
    </div>
  );
}
