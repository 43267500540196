import { gql } from "@apollo/client";

export const GET_WITHDRAWAL_METHOD_BY_ID = gql`
  query GetWithdrawalMethodById($withdrawalMethodId: ID!) {
    getWithdrawalMethodById(withdrawalMethodId: $withdrawalMethodId) {
      id
      name
      provider
      payout
      currency
      type
      countries
      image
      priority
      tremendousId
      description
      fee
    }
  }
`;
