import { gql } from "@apollo/client";

export const GET_FEATURED_OFFER_BY_ID = gql`
	query GetFeaturedOfferById($featuredOfferId: ID!) {
		getFeaturedOfferById(id: $featuredOfferId) {
			id
			name
			image
			countries
			link
			payout
			provider
			description
			oss
		}
	}
`;
