import { gql } from "@apollo/client";

export const GET_ALL_WITHDRAWAL_METHODS = gql`
  query GetAllWithdrawalMethods {
    getAllWithdrawalMethods {
      id
      name
      provider
      payout
      image
    }
  }
`;
