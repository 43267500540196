import { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";

import { NotificationContext } from "context/notification";

import { GET_USER_BY_ID } from "api/query/get-user-by-id";
import { UPDATE_USER } from "api/mutations/update-user";

import Drawer from "common/components/drawer";
import Input from "common/components/input";
import Button from "common/components/button";
import Switch from "common/components/switch";
import Select from "common/components/select";

const inputs = [
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Phone", key: "phone" },
];

function UserDrawer({ userId, forceCloseDrawer = () => {}, refetchUsers }) {
  const [banned, banUser] = useState(false);
  const [verified, verifyUser] = useState(false);
  const [isAdmin, setAdmin] = useState(false);
  const [isEditor, setEditor] = useState(false);
  const [promoter, setPromoter] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [payload, setPayload] = useState({});
  const [invalidIp, setInvalidIp] = useState("");

  const { setNotification } = useContext(NotificationContext);

  const navigate = useNavigate();

  // Fetch user data
  const { data: { getUserById: user } = {} } = useQuery(GET_USER_BY_ID, {
    variables: {
      userId,
    },
  });

  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    onCompleted: (d) => {
      setNotification({
        type: "success",
        message: "Successfully updated",
      });
      forceCloseDrawer(true);
      refetchUsers();
    },
    onError: (d) => {
      setNotification({
        type: "error",
        message: "Something went wrong",
      });
    },
  });

  useEffect(() => {
    if (user) {
      setPayload({
        name: user.name,
        email: user.email,
        phone: user.phone,
      });
      verifyUser(user.verified);
      setAdmin(user.isAdmin);
      setDuplicate(user.duplicate);
      setEditor(user.isEditor);
      setPromoter(user.promoter);
      banUser(user.banned);
      setInvalidIp(user.invalidIp);
      setEmailVerified(user.emailVerified);
    }
  }, [user]);

  const handleUpdate = () => {
    updateUser({
      variables: {
        userId,
        payload: {
          ...payload,
          invalidIp,
          verified,
          isAdmin,
          isEditor,
          promoter,
          banned,
          duplicate,
          emailVerified,
        },
      },
    });
  };

  return (
    <div className="flex flex-col gap-6">
      {inputs.map((input, idx) => (
        <Input
          key={idx}
          label={input.label}
          placeholder={input.label}
          defaultValue={payload[input.key]}
          onChange={(text) =>
            setPayload((prevState) => ({ ...prevState, [input.key]: text }))
          }
        />
      ))}

      <Switch enabled={invalidIp} setEnabled={setInvalidIp} label="Proxy/VPN" />
      <Switch enabled={banned} setEnabled={banUser} label="Banned" />
      <Switch
        enabled={emailVerified}
        setEnabled={setEmailVerified}
        label="Email Verified"
      />
      <Switch enabled={verified} setEnabled={verifyUser} label="Verified" />
      <Switch enabled={duplicate} setEnabled={setDuplicate} label="Duplicate" />
      <Switch enabled={isEditor} setEnabled={setEditor} label="Is Editor" />
      <Switch enabled={isAdmin} setEnabled={setAdmin} label="Is Admin" />
      <Switch enabled={promoter} setEnabled={setPromoter} label="Promoter" />

      <Button
        title="Save changes"
        className="text-indigo-600 border-indigo-600 hover:text-indigo-500 hover:border-indigo-500"
        onClick={handleUpdate}
      />
    </div>
  );
}

export default function EditUser({ refetchUsers }) {
  const [forceClose, setForceClose] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const closeDrawer = () => {
    navigate("/users");
  };

  return (
    <Drawer
      active={true}
      onClose={closeDrawer}
      title="Edit User"
      forceClose={forceClose}
      setForceClose={setForceClose}
      component={
        <UserDrawer
          refetchUsers={refetchUsers}
          forceCloseDrawer={setForceClose}
          userId={params.id}
        />
      }
    />
  );
}
