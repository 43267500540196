import { gql } from "@apollo/client";

export const UPDATE_ANNOUNCEMENT = gql`
  mutation EditAnnouncement($payload: EditAnnouncement!, $id: ID!) {
    editAnnouncement(payload: $payload, id: $id)
  }
`;

export const ADD_ANNOUNCEMENT = gql`
  mutation AddNewAnnouncement($payload: CreateAnnouncement!) {
    addNewAnnouncement(payload: $payload) {
      id
    }
  }
`;

export const DELETE_ANNOUNCEMENT = gql`
  mutation DeleteAnnouncement($id: ID!) {
    deleteAnnouncement(id: $id)
  }
`;
