import { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { NotificationContext } from "context/notification";

import { ADD_FEATURED_OFFER } from "api/mutations/featured-offer";

import Drawer from "common/components/drawer";
import Input from "common/components/input";
import Textarea from "common/components/textarea";
import Button from "common/components/button";

const inputs = [
	{
		label: "Name",
		key: "name",
	},
	{
		label: "Provider",
		key: "provider",
	},
	{
		label: "Image",
		key: "image",
	},
	{
		label: "Payout ($)",
		key: "payout",
	},
	{
		label: "Link",
		key: "link",
	},
	{
		label: "Countries (Codes separated by comma)",
		key: "countries",
	},
	{
		label: "OSS (Codes separated by comma) - (android, ios, web)",
		key: "oss",
	},
];

const textareas = [
	{
		label: "Description",
		key: "description",
	},
];

const FeaturedOfferDrawer = ({ forceCloseDrawer, refetchFeaturedOffers }) => {
	const [payload, setPayload] = useState({});

	const { setNotification } = useContext(NotificationContext);

	const [addFeaturedOffer, { loading }] = useMutation(ADD_FEATURED_OFFER, {
		onCompleted: (d) => {
			setNotification({
				type: "success",
				message: "Successfully added",
			});
			forceCloseDrawer(true);
			refetchFeaturedOffers();
		},
		onError: (d) => {
			if (d?.message) {
				setNotification({
					type: "error",
					message: d.message,
				});
			} else {
				setNotification({
					type: "error",
					message: "Something went wrong",
				});
			}
		},
	});

	const handleAdd = () => {
		addFeaturedOffer({
			variables: {
				payload: {
					name: payload.name,
					payout: parseFloat(payload.payout),
					countries: payload.countries,
					link: payload.link,
					provider: payload.provider,
					image: payload.image,
					description: payload.description,
					oss: payload.oss,
				},
			},
		});
	};

	return (
		<div className="flex flex-col gap-6">
			{inputs.map((input, idx) => (
				<Input
					key={idx}
					label={input.label}
					placeholder={input.label}
					defaultValue={payload[input.key]}
					onChange={(text) =>
						setPayload((prevState) => ({ ...prevState, [input.key]: text }))
					}
				/>
			))}

			{textareas.map((input, idx) => (
				<Textarea
					key={idx}
					label={input.label}
					placeholder={input.label}
					defaultValue={payload[input.key]}
					onChange={(text) =>
						setPayload((prevState) => ({ ...prevState, [input.key]: text }))
					}
				/>
			))}

			<Button
				title="Add Featured Offer"
				className="text-indigo-600 border-indigo-600 hover:text-indigo-500 hover:border-indigo-500"
				onClick={handleAdd}
			/>
		</div>
	);
};

const Add = ({ refetchFeaturedOffers }) => {
	const [forceClose, setForceClose] = useState(false);

	const navigate = useNavigate();

	const closeDrawer = () => {
		navigate("/featured-offers");
	};

	return (
		<Drawer
			active={true}
			onClose={closeDrawer}
			title="Add Featured Offer"
			forceClose={forceClose}
			setForceClose={setForceClose}
			component={
				<FeaturedOfferDrawer
					refetchFeaturedOffers={refetchFeaturedOffers}
					forceCloseDrawer={setForceClose}
				/>
			}
		/>
	);
};

export default Add;
