import { gql } from "@apollo/client";

export const GET_ALL_OFFERWALLS = gql`
  query GetAllOfferwalls {
    getAllOfferwalls {
      id
      name
      iframe
      image
      priority
      postback
    }
  }
`;
