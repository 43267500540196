import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useLocation, Routes, Route, useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";

import { CheckIcon, XIcon, PencilIcon } from "@heroicons/react/outline";

import EditCountry from "./components/edit";

import Table from "common/components/table";

import { GET_ALL_COUNTRIES } from "api/query/get-all-countries";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const header = [
  {
    key: "id",
    value: "ID",
    sortable: true,
  },
  {
    key: "name",
    value: "NAME",
    sortable: true,
  },
  {
    key: "code",
    value: "CODE",
    sortable: true,
  },
  {
    key: "totalUsers",
    value: "TOTAL USERS",
    sortable: true,
  },
  {
    key: "blocked",
    value: "BLOCKED",
  },
  {
    key: "fraudCheck",
    value: "FRAUD CHECK",
  },
  {
    key: "minValueFraudCheck",
    value: "MIN VALUE FRAUD",
    sortable: true,
  },
];

const getIcon = (state) => {
  return state ? (
    <CheckIcon className="w-5 h-5" color="#22c55e" />
  ) : (
    <XIcon className="w-5 h-5" color="#ef4444" />
  );
};

export default function Countries({ setSidebarOpen }) {
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState({});
  const [data, setData] = useState([]);
  const [totalCountries, setTotal] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const {
    client,
    data: { getAllCountries: { countries, total } = {} } = {},
    refetch: refetchCountries,
  } = useQuery(GET_ALL_COUNTRIES, {
    fetchPolicy: "cache-and-network",
    variables: {
      page,
      sortBy,
    },
  });

  useEffect(() => {
    if (countries) {
      setData(countries);
    }
  }, [countries]);

  useEffect(() => {
    if (total !== totalCountries && total) {
      setTotal(total);
    }
  }, [total]);

  useEffect(() => {
    if (parseInt(searchParams.get("page"), 10) > 0) {
      setPage(parseInt(searchParams.get("page")));
    }
  }, [searchParams.get("page")]);

  const actions = [
    {
      icon: PencilIcon,
      onClick: (country) => {
        navigate(`edit/${country.id}`);
      },
      color: "#6366f1",
    },
  ];

  const sortTableBy = (name) => {
    // Reset page to 1
    setPage(1);

    setSortBy((prevState) => ({
      name,
      type: prevState.type === "DESC" ? "ASC" : "DESC",
    }));
  };

  return (
    <div className="mx-4">
      <Routes>
        <Route
          path="/edit/:id"
          element={<EditCountry refetchCountries={refetchCountries} />}
        />
      </Routes>
      <div className="px-4 py-4 sm:px-6 md:px-0">
        <h1 className="text-3xl font-bold text-gray-700">Countries</h1>
      </div>
      <Table
        header={header.map((hdr) => {
          if (hdr.sortable) {
            hdr.onClick = sortTableBy;
          }

          return hdr;
        })}
        page={page}
        total={totalCountries}
        actions={actions}
        data={data?.map((country) => ({
          ...country,
          renderActions: true,
          fraudCheck: getIcon(country.fraudCheck),
          blocked: getIcon(country.blocked),
          name: (
            <>
              <ReactCountryFlag
                svg
                className="w-8 h-8 mr-2 shadow"
                countryCode={country.code}
                title={country.name}
              />
              {country.name}
            </>
          ),
        }))}
      />
    </div>
  );
}
