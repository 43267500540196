import { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { NotificationContext } from "context/notification";

import { ADD_REWARD } from "api/mutations/reward";

import Drawer from "common/components/drawer";
import Input from "common/components/input";
import Button from "common/components/button";
import Select from "common/components/select";

const inputs = [
  {
    label: "Name",
    key: "name",
  },
  {
    label: "Provider",
    key: "provider",
  },
  {
    label: "Transaction",
    key: "transaction",
  },
  {
    label: "Amount (Points)",
    key: "amount",
  },
  {
    label: "Our Part (Points)",
    key: "ourPart",
  },
  {
    label: "Payout ($$)",
    key: "payout",
  },
  {
    label: "User ID",
    key: "userId",
  },
  {
    label: "User IP",
    key: "userIp",
  },
];

const rewardStatuses = [
  {
    id: 1,
    value: "Pending",
    key: 0,
  },
  {
    id: 2,
    value: "Completed",
    key: 1,
  },
  {
    id: 3,
    value: "Rejected",
    key: 2,
  },
];

const RewardDrawer = ({ forceCloseDrawer, refetchRewards }) => {
  const [payload, setPayload] = useState({});

  const { setNotification } = useContext(NotificationContext);

  const [addReward, { loading }] = useMutation(ADD_REWARD, {
    onCompleted: (d) => {
      setNotification({
        type: "success",
        message: "Successfully added",
      });
      forceCloseDrawer(true);
      refetchRewards();
    },
    onError: (d) => {
      if (d?.message) {
        setNotification({
          type: "error",
          message: d.message,
        });
      } else {
        setNotification({
          type: "error",
          message: "Something went wrong",
        });
      }
    },
  });

  const handleAdd = () => {
    addReward({
      variables: {
        payload: {
          ...payload,
          amount: parseFloat(payload.amount, 10),
          ourPart: parseFloat(payload.ourPart, 10),
          status: payload.status || 0,
          payout: parseFloat(payload.payout, 10),
        },
      },
    });
  };

  return (
    <div className="flex flex-col gap-6">
      {inputs.map((input, idx) => (
        <Input
          key={idx}
          label={input.label}
          placeholder={input.label}
          defaultValue={payload[input.key]}
          onChange={(text) =>
            setPayload((prevState) => ({ ...prevState, [input.key]: text }))
          }
        />
      ))}

      <Select
        label="Status"
        data={rewardStatuses}
        onChange={(item) => {
          setPayload((prevState) => ({
            ...prevState,
            status: item.key,
          }));
        }}
      />

      <Button
        title="Add Reward"
        className="text-indigo-600 border-indigo-600 hover:text-indigo-500 hover:border-indigo-500"
        onClick={handleAdd}
      />
    </div>
  );
};

const Add = ({ refetchRewards }) => {
  const [forceClose, setForceClose] = useState(false);

  const navigate = useNavigate();

  const closeDrawer = () => {
    navigate("/rewards");
  };

  return (
    <Drawer
      active={true}
      onClose={closeDrawer}
      title="Add Reward"
      forceClose={forceClose}
      setForceClose={setForceClose}
      component={
        <RewardDrawer refetchRewards={refetchRewards} forceCloseDrawer={setForceClose} />
      }
    />
  );
};

export default Add;
