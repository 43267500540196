import { useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate, Routes, Route, Link } from "react-router-dom";
import { CheckIcon, XIcon, PlusCircleIcon } from "@heroicons/react/outline";

import { PencilIcon } from "@heroicons/react/outline";

import { NotificationContext } from "context/notification";

import EditReview from "./components/edit";
import AddReview from "./components/add";

import Drawer from "common/components/drawer";
import Card from "common/components/card";
import Modal from "common/components/modal";
import Button from "common/components/button";

import { GET_ALL_REVIEWS } from "api/query/get-all-reviews";
import { DELETE_REVIEW } from "api/mutations/review";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const getIcon = (state) => {
  return state ? (
    <CheckIcon className="w-5 h-5 ml-[50%] -translate-x-1/2" color="#22c55e" />
  ) : (
    <XIcon className="w-5 h-5 ml-[50%] -translate-x-1/2" color="#ef4444" />
  );
};

export default function Reviews({ setSidebarOpen }) {
  const [modalActive, setModalActive] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { setNotification } = useContext(NotificationContext);
  const navigate = useNavigate();

  const { data: { getAllReviews: reviews } = {}, refetch: refetchReviews } = useQuery(
    GET_ALL_REVIEWS,
    {}
  );

  const [deleteReviewMutation, { loading }] = useMutation(DELETE_REVIEW, {
    onCompleted: (d) => {
      setNotification({
        type: "success",
        message: "Successfully deleted",
      });
      refetchReviews();
    },
    onError: (d) => {
      setNotification({
        type: "error",
        message: "Something went wrong",
      });
    },
  });

  const onEdit = (review) => {
    navigate(`edit/${review.id}`);
  };

  const deleteReview = (id) => {
    if (id !== null) {
      deleteReviewMutation({
        variables: {
          reviewID: id,
        },
      });
    }
  };

  const onDelete = (review) => {
    setDeleteId(review.id);
    setModalActive(true);
  };

  const onModalClose = () => {
    setDeleteId(null);
  };

  const addReviewDrawer = () => {
    navigate("add");
  };

  const generateDescription = (review = {}) => {
    return (
      <div className="text-xs text-gray-500">
        <p className="break-words">
          Name: <span className="text-gray-400">{review.name}</span>
        </p>
        <p className="mt-2 break-words">
          Rate: <span className="text-gray-400">{review.rate}</span>
        </p>
        <p className="mt-2 break-words">
          Description: <span className="text-gray-400">{review.description}</span>
        </p>
      </div>
    );
  };

  return (
    <div className="mx-4">
      <Routes>
        <Route
          path="/edit/:id"
          element={<EditReview refetchReviews={refetchReviews} />}
        />
        <Route path="/add" element={<AddReview refetchReviews={refetchReviews} />} />
      </Routes>
      <Modal
        active={modalActive}
        setActive={setModalActive}
        title="Are you sure?"
        message="You are about to delete an Review"
        onSubmit={() => deleteReview(deleteId)}
      />
      <div className="px-4 py-4 sm:px-6 md:px-0">
        <h1 className="text-3xl font-bold text-gray-700">Reviews</h1>
      </div>
      <div className="my-4 flex flex-wrap gap-4">
        {reviews?.map((review, idx) => (
          <Card
            key={idx}
            image={review.avatar}
            description={generateDescription(review)}
            onEdit={() => onEdit(review)}
            onDelete={() => onDelete(review)}
          />
        ))}

        <div
          className="shadow bg-white min-h-[200px] w-[210px] max-h-[300px] rounded-md overflow-hidden relative mx-auto xs:mx-0 cursor-pointer flex justify-center items-center flex-col gap-1"
          onClick={addReviewDrawer}
        >
          <PlusCircleIcon className="w-8 h-8 text-gray-400" />
          <span className="text-xs text-gray-400">Add new</span>
        </div>
      </div>
    </div>
  );
}
