import { useState, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { MenuIcon } from "@heroicons/react/outline";

import Sidebar from "./components/sidebar";

import Notification from "common/components/notification";

import { UserContext } from "context/user";

import routes from "shell/routes";

import "./layout.css";

const Wrapper = ({ Component, setSidebarOpen }) => {
  return (
    <>
      <div className="md:pl-64 flex flex-col flex-1">
        <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1">
          <div className="py-6 mx-auto px-5">
            <Component />
          </div>
        </main>
      </div>
    </>
  );
};

const Layout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { user } = useContext(UserContext);

  if (!user?.isAdmin && !user?.isEditor) {
    return <div></div>;
  }

  const editorRoutes = ["/chat"];

  return (
    <div className="layout">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Routes>
        {routes
          .filter((route) => user?.isAdmin || editorRoutes.indexOf(route.path) > -1)
          .map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <Wrapper Component={route.component} setSidebarOpen={setSidebarOpen} />
                }
              />
            );
          })}
      </Routes>

      <Notification />
    </div>
  );
};

export default Layout;
