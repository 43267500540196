import { createContext, useState, useEffect } from "react";

const NotificationContext = createContext({});

const NotificationProvider = ({ children }) => {
  const [payload, setPayload] = useState({
    message: "",
    type: "",
  });

  useEffect(() => {
    if (payload.message && payload.type) {
      setShow(true);
    }
  }, [payload]);

  const [show, setShow] = useState(false);

  return (
    <NotificationContext.Provider
      value={{
        payload,
        setNotification: setPayload,
        show,
        setShow,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationProvider, NotificationContext };
