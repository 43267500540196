import { useState, useEffect } from "react";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Input({
	label = "",
	type = "text",
	onChange = () => {},
	labelStyle = {},
	inputStyle = {},
	placeholder = "",
	defaultValue = "",
	prefix = "",
	sufix = "",
	className = "",
	value = "",
}) {
	const [text, setText] = useState("");

	const onInputChange = (e) => {
		setText(e.target.value);
	};

	useEffect(() => {
		onChange(text || "");
	}, [text]);

	useEffect(() => {
		if (defaultValue) {
			setText(defaultValue || "");
		} else if (!text) {
			setText("");
		}
	}, [defaultValue]);

	useEffect(() => {
		if (value !== text) {
			setText(value);
		}
	}, [value]);

	return (
		<div className={className}>
			<label className="block text-sm font-medium text-gray-700" style={labelStyle}>
				{label}
			</label>
			<div className="mt-1 relative rounded-md shadow-sm">
				{prefix ? (
					<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
						<span className="text-gray-500 sm:text-sm">{prefix}</span>
					</div>
				) : null}
				<input
					type={type}
					style={inputStyle}
					value={text}
					onChange={onInputChange}
					className={classNames(
						`focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`,
						prefix ? "pl-8" : "pl-4",
						sufix ? "pr-12" : "pr-4"
					)}
					placeholder={placeholder}
				/>
				{sufix ? (
					<div className="absolute inset-y-0 right-0 pl-3 flex items-center">
						<span className="text-gray-500 sm:text-sm">{sufix}</span>
					</div>
				) : null}
			</div>
		</div>
	);
}
