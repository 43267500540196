import { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";

import { NotificationContext } from "context/notification";

import { GET_WITHDRAWAL_METHOD_BY_ID } from "api/query/get-withdrawal-method-by-id";
import { UPDATE_WITHDRAWAL_METHOD } from "api/mutations/withdrawal-method";

import Drawer from "common/components/drawer";
import Input from "common/components/input";
import Button from "common/components/button";

const inputs = [
	{
		label: "Name",
		key: "name",
	},
	{
		label: "Payout",
		key: "payout",
	},
	{
		label: "Image",
		key: "image",
	},
	{
		label: "Payout type (cards, crypto, coinbase)",
		key: "type",
	},
	{
		label: "Currency (USD, LTC, etc)",
		key: "currency",
	},
	{
		label: "Countries (Separated by comma)",
		key: "countries",
	},
	{
		label: "Provider",
		key: "provider",
	},
	{
		label: "Priority",
		key: "priority",
	},
	/*
  {
    label: "Tremendous Id",
    key: "tremendousId",
  },
	*/
	{
		label: "Description",
		key: "description",
	},
	{
		label: "Fee",
		key: "fee",
	},
];

const WithdrawalMethodDrawer = ({
	withdrawalMethodId,
	refetchWithdrawalMethods,
	forceCloseDrawer,
}) => {
	const [payload, setPayload] = useState({});

	const { setNotification } = useContext(NotificationContext);

	const { data: { getWithdrawalMethodById: withdrawalMethod } = {} } = useQuery(
		GET_WITHDRAWAL_METHOD_BY_ID,
		{
			variables: {
				withdrawalMethodId,
			},
		}
	);

	const [updateWithdrawalMethod, { loading }] = useMutation(UPDATE_WITHDRAWAL_METHOD, {
		onCompleted: (d) => {
			setNotification({
				type: "success",
				message: "Successfully updated",
			});
			forceCloseDrawer(true);
			refetchWithdrawalMethods();
		},
		onError: (d) => {
			setNotification({
				type: "error",
				message: "Something went wrong",
			});
		},
	});

	useEffect(() => {
		if (withdrawalMethod) {
			setPayload({
				...withdrawalMethod,
				countries: (withdrawalMethod.countries || []).join(","),
			});
		}
	}, [withdrawalMethod]);

	const handleUpdate = () => {
		updateWithdrawalMethod({
			variables: {
				withdrawalMethodId,
				payload: {
					name: payload.name,
					payout: parseFloat(payload.payout, 10),
					image: payload.image,
					currency: payload.currency,
					provider: payload.provider,
					countries: (payload.countries || "").split(","),
					priority: parseInt(payload.priority, 10),
					//tremendousId: payload.tremendousId,
					type: payload.type,
					description: payload.description,
					fee: payload.fee,
				},
			},
		});
	};

	return (
		<div className="flex flex-col gap-6">
			{inputs.map((input, idx) => (
				<Input
					key={idx}
					label={input.label}
					placeholder={input.label}
					defaultValue={payload[input.key]}
					onChange={(text) =>
						setPayload((prevState) => ({ ...prevState, [input.key]: text }))
					}
				/>
			))}

			<Button
				title="Save changes"
				className="text-indigo-600 border-indigo-600 hover:text-indigo-500 hover:border-indigo-500"
				onClick={handleUpdate}
			/>
		</div>
	);
};

const Edit = ({ refetchWithdrawalMethods }) => {
	const [forceClose, setForceClose] = useState(false);

	const params = useParams();
	const navigate = useNavigate();

	const closeDrawer = () => {
		navigate("/withdrawal-methods");
	};

	return (
		<Drawer
			active={true}
			onClose={closeDrawer}
			title="Edit Withdrawal Method"
			forceClose={forceClose}
			setForceClose={setForceClose}
			component={
				<WithdrawalMethodDrawer
					refetchWithdrawalMethods={refetchWithdrawalMethods}
					forceCloseDrawer={setForceClose}
					withdrawalMethodId={params.id}
				/>
			}
		/>
	);
};

export default Edit;
