import { gql } from "@apollo/client";

export const GET_COUNTRY_BY_ID = gql`
  query GetCountryById($countryId: ID!) {
    getCountryById(countryId: $countryId) {
      id
      name
      code
      totalUsers
      blocked
      fraudCheck
      minValueFraudCheck
    }
  }
`;
