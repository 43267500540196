import { useState, useEffect, memo } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

function classNames(...props) {
  return props.join(" ");
}

function getPreviousParams() {
  let index = String(window.location).indexOf("?") + 1;

  if (index === 0) {
    return {};
  }

  const qs = String(window.location).slice(index).split("&");

  return qs.reduce((acc, curr) => {
    const item = curr.split("=");
    acc[item[0]] = item[1];

    return acc;
  }, {});
}

const Pagination = ({
  current = 1,
  total = 0,
  perPage = 10,
  onPageChange,
  pageParam = "page",
}) => {
  const [curr, setCurr] = useState(current);
  const [totalPages, setTotal] = useState(Math.ceil(total / perPage));

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (current > 0) {
      setCurr(current);
    }
  }, [current]);

  useEffect(() => {
    setTotal(Math.ceil(total / perPage));
  }, [total, perPage]);

  useEffect(() => {
    if (curr > 0 && curr <= totalPages) {
      onPageChange(curr);
      // Push to query current page
      const params = new URLSearchParams({ ...getPreviousParams(), [pageParam]: curr });

      navigate({
        pathname: location.pathname,
        search: params.toString(),
      });
    }
  }, [curr]);

  const incrementPage = (val) => {
    if (curr + val !== 0 && curr + val <= totalPages) {
      setCurr((prevState) => prevState + val);
    }
  };

  const setCurrentPage = (val) => {
    if (val > 0 && val <= totalPages && val !== curr) {
      setCurr(val);
    }
  };

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between lg:hidden">
        <span
          onClick={() => incrementPage(-1)}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
        >
          Previous
        </span>
        <span
          onClick={() => incrementPage(1)}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
        >
          Next
        </span>
      </div>
      <div className="hidden lg:flex-1 lg:flex lg:items-center lg:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{perPage}</span> to{" "}
            <span className="font-medium">
              {curr * perPage > total ? total : curr * perPage}
            </span>{" "}
            of <span className="font-medium">{total}</span> results
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <span
              onClick={() => incrementPage(-1)}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
            {[1, 2, 3].map((item, idx) => {
              let page = totalPages > 6 && totalPages - curr >= 3 ? curr + idx : item;
              if (totalPages > 6 && curr + 5 > totalPages) {
                page = totalPages - 6 + item;
                if (!(totalPages > 6 && totalPages - curr >= 3)) {
                  page = item;
                }
              }
              if (totalPages < page) {
                return null;
              }
              return (
                <span
                  key={page}
                  onClick={() => setCurrentPage(page)}
                  className={classNames(
                    curr === page
                      ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
                      : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50",
                    "relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
                  )}
                >
                  {page}
                </span>
              );
            })}
            {totalPages > 6 && (curr + 5 < totalPages || curr + 3 > totalPages) ? (
              <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                ...
              </span>
            ) : null}
            {[3, 2, 1].map((item, idx) => {
              const page = totalPages - 2 + idx;
              if (totalPages - 3 < item) {
                return null;
              }
              return (
                <span
                  key={page}
                  onClick={() => setCurrentPage(page)}
                  className={classNames(
                    curr === page
                      ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
                      : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50",
                    "relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
                  )}
                >
                  {page}
                </span>
              );
            })}
            <span
              onClick={() => incrementPage(1)}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default memo(Pagination);
