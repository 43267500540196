import { gql } from "@apollo/client";

export const GET_OFFERWALL_BY_ID = gql`
  query GetOfferwallById($offerwallId: ID!) {
    getOfferwallById(offerwallId: $offerwallId) {
      id
      chargebackType
      whitelist
      name
      iframe
      sticker
      image
      priority
      completedStatusCode
      rejectedStatusCode
      hasSurveys
      isPopular
      highEarning
      active
      countries
      levelRestriction
      hideFromProxy
    }
  }
`;
